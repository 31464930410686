<section>
  <div class="d-flex addressFormContainer">
    <div id="left">
      <form class="form-inline">
        <h1 class="txt">Επιλέξτε την διεύθυνση παραλαβής και παράδοσης</h1>
        <div class="undertxt">
          lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          in nulla dictum, iaculis nunc non, sollicitudin metus. Nulla pulvinar
          laoreet odio sit amet lacinia.
        </div>

        <div id="addressFlex">
          <!-- input class="form-control form-control-width w-75" type="search" placeholder="διεύθυνση" aria-label="Search"> -->
          <div class="dropdown">
            <div
              class="form-control-width"
              type="text"
              id="dropdownMenuButton"
              placeholder="Διευθυνση..."
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div id="ddtext" *ngIf="dropdownItem">
                <img
                  id="placePin"
                  src="../../../assets/svgWeb/ic_place_24px.svg"
                />
                {{ dropdownItem }}
                <div class="move"><i class="arrow down"></i></div>
              </div>
              <div id="ddtext" *ngIf="!dropdownItem">
                <div>
                  <img
                    id="placePin"
                    src="../../../assets/svgWeb/ic_place_24px.svg"
                  />
                </div>
                Διαθέσιμες διευθύνσεις
                <div class="move"><i class="arrow down"></i></div>
              </div>
            </div>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div
                id="delete"
                class="dropdown-item"
                id="ddtext2"
                *ngFor="let address of addresses"
              >
                <div class="dropdownRow">
                  <div (click)="chooseAddress(address)">
                    {{ address.address }} {{ address.number }}
                    {{ address.city }}
                    {{ address.postalCode }}
                  </div>

                  <div class="foreground" (click)="deleteAdress(address.id)">
                    <img
                      class="trashcan"
                      src=" ../../../assets/trash-2-outline.svg"
                    />
                  </div>
                </div>
              </div>

              <!-- (click)=""
                  -->
            </div>
          </div>

          <button
            class="btn btn-outline-light blue-background white"
            (click)="confirmAddress()"
            ng-disabled="!dropdownItem"
            type="button"
          >
            Επιλογή
          </button>
        </div>
      </form>

      <!-- <div class="txt2">π.χ. Ολύμπου 12, Θεσ/νίκη</div> -->

      <div id="reglink">
        <div style="font-weight: bold">
          + <a [routerLink]="['/add']"> Προσθήκη νέας διεύθυνσης </a>
        </div>
      </div>
    </div>

    <div class="right">
      <div id="img">
        <img id="photof" src="../../../assets/app_DL.png" />
      </div>

      <div>
        <app-download-buttons></app-download-buttons>
      </div>
    </div>
  </div>
</section>
