import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbWindowService } from '@nebular/theme';

import { Observable, Subscription } from 'rxjs';
import { elementAt, take } from 'rxjs/operators';
import { ICalendar } from 'src/app/stores/models/Calendar';
import { CartItem, ICart, ICartTotals } from 'src/app/stores/models/Cart';
import { ISingleStore } from 'src/app/stores/models/singleStore';
import { IStore } from 'src/app/stores/models/store';
import { CartService } from 'src/app/stores/services/cart.service';
import { OrdersService } from 'src/app/stores/services/orders.service';
import { RequestsService } from 'src/app/stores/services/requests.service';
import * as moment from 'moment';
import { UserService } from 'src/app/_services/user.service';
import { IUser } from 'src/app/stores/models/User';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit {
  @ViewChild('invoice') templateRef: TemplateRef<any>;
  profile: IUser;
  stores: IStore[] = [];
  singleStore: ISingleStore[] = [];
  storeCalendar: ICalendar;
  deliveryDays: Array<any> = [];
  pickupDays: Array<any> = [];
  date = new FormControl(new Date());
  datePickup = new FormControl(new Date());
  deliveryDateIndex: number;
  pickupDateIndex: number;

  storeId: string;
  selectedStore: IStore;

  cart$: Observable<ICart>;
  CartTotal: number = 0;
  subTotal: number = 0;
  cartTotal$: Observable<ICartTotals>;
  localCart: ICart;
  postCode: string; //////////////////////////////////////// = '16777'

  inviteMap: any = { card: 'Κάρτα', cash: 'Μετρητά', pos: 'POS' };
  checkoutForm: FormGroup;
  offsetDays: number;
  minimuPickupDate: any;

  groupType: 'double' | 'single' | string = 'double';

  myFilter = (date: Date): boolean => {
    return true;
  };
  myFilter2 = (date: Date): boolean => {
    return true;
  };

  cartSubscription: Subscription;
  closeResult: string;

  constructor(
    private storeReq: RequestsService,
    private cartService: CartService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private orderService: OrdersService,
    private user: UserService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    let token = localStorage.getItem('token');
    console.log(token);
    let user = localStorage.getItem('userId');
    console.log(user);
    this.user.getUserProfile(token, user).subscribe((data) => {
      this.profile = data;
      console.log(this.profile);
    });
    this.postCode = localStorage.getItem('postalCode');
    let myJSON = localStorage.getItem('localCart');
    this.localCart = JSON.parse(myJSON);
    console.log('----------------', this.localCart);

    this.storeId = this.route.snapshot.paramMap.get('store');
    console.log(this.storeId);

    this.groupType = this.route.snapshot.paramMap.get('grouptype');

    this.cartSubscription = this.cartService.cart$
      .pipe(take(1))
      .subscribe((newCart) => {
        if (!newCart) {
          this.router.navigate(['/single?store=' + this.storeId]);
        }
        this.initiateForm(newCart.totalPrice, newCart.items);
        this.CartTotal = newCart.totalPrice;
        this.offsetDays = this.findOffset(newCart.items);
      });

    this.storeReq
      .getSingleStoreCalendar(this.storeId, this.postCode)
      .subscribe((data: any) => {
        this.storeCalendar = data;
        // console.log(this.storeCalendar);
        this.getDelivery();
        this.getDeliveryDates();
        this.getPickup();
        this.getPickupDates();
      });
    // selected store undefined
    this.storeReq.getStores().subscribe((data) => {
      this.stores = data;
      console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!', this.stores);
      this.selectedStore = this.stores.find(
        (store) => store.id === this.storeId
      );
    });

    this.storeReq
      .getSingleStorebyId(this.storeId)
      .subscribe((data) => (this.singleStore = data));
    console.log('??????????????????????????????', this.singleStore); ///mallon axrhsto

    this.cart$ = this.cartService.cart$;
    this.cartTotal$ = this.cartService.cartTotal$;
    this.getTotals();
  }

  initiateForm(totalPrice, items) {
    this.checkoutForm = this.fb.group({
      comments: [''],
      pickupDay: [null, [Validators.required]],
      pickupTime: [null, [Validators.required]],
      deliveryDay: new FormControl({
        value: null,
        required: this.groupType === 'double-calendar',
      }),
      deliveryTime: new FormControl({
        value: null,
        required: this.groupType === 'double-calendar',
      }),
      paymentType: ['cash', [Validators.required]],
      issueInvoice: [false, [Validators.required]],
      storeId: [this.storeId, [Validators.required]],
      totalPrice: [totalPrice, [Validators.required]],
      items: [items, [Validators.required]],
      deliveryFee: [],
    });
  }

  getDelivery() {
    console.log(this.storeCalendar.deliveryDaysCalendar.length);
    for (let i = 0; i < this.storeCalendar.deliveryDaysCalendar.length; i++) {
      this.deliveryDays[i] = this.storeCalendar.deliveryDaysCalendar[i].date;
    }

    // console.log(this.deliveryDays);
    return this.deliveryDays;
  }

  getPickup() {
    console.log(this.storeCalendar.pickUpDaysCalendar.length);
    for (let i = 0; i < this.storeCalendar.pickUpDaysCalendar.length; i++) {
      this.pickupDays[i] = this.storeCalendar.pickUpDaysCalendar[i].date;
    }
    // console.log(this.pickupDays);
    return this.pickupDays;
  }

  getTotals() {
    console.log('checkme1');

    let priceSum = 0;
    const cart = this.localCart;

    if (cart === null) {
      this.CartTotal = 0;
    } else {
      console.log('++++++++++++', cart);
      cart.items.forEach((element) => {
        element.extras.forEach((extra) => {
          priceSum += extra.quantity * Number(extra.price);
        });
        this.CartTotal = cart.totalPrice;
        console.log('sum', priceSum);
        console.log(element);
        console.log('checkme2');
      });
    }
  }

  cartPreview() {
    console.log(this.cartService.getCurrentCartValue());
    const cart = this.localCart;
    if (cart != null) {
      console.log(cart.items[0].name);
    }
  }

  getPickupDates() {
    this.myFilter2 = (d: Date): boolean => {
      const datesArray2 = this.pickupDays.map(
        (dateString) => new Date(dateString)
      );

      return (
        datesArray2.findIndex(
          (valid_date) => d?.toDateString() === valid_date?.toDateString()
        ) >= 0
      );
    };
  }

  getDeliveryDates() {
    this.myFilter = (d: Date): boolean => {
      const datesArray = this.deliveryDays.map(
        (dateString) => new Date(dateString)
      );

      return (
        datesArray.findIndex(
          (valid_date) => d?.toDateString() === valid_date?.toDateString()
        ) >= 0
      );
    };
  }

  popDropdownHoursPickup(data: any) {
    this.minimuPickupDate = this.addDays(data, this.offsetDays);
    console.log(data);
    console.log(this.minimuPickupDate);
    console.log(data);
    this.pickupDateIndex = this.pickupDays.findIndex((date) =>
      this.datesAreOnSameDay(data, new Date(date))
    );

    console.log(this.pickupDays[this.pickupDateIndex]);
  }

  popDropdownHours(data: any) {
    this.deliveryDateIndex = this.deliveryDays.findIndex((date) =>
      this.datesAreOnSameDay(data, new Date(date))
    );

    console.log(this.deliveryDays[this.deliveryDateIndex]);
  }

  datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  onSubmit(value) {
    console.log(value);
    const pickupTime = value.pickupTime.split('==')[0];
    const minimumOrderPrice = value.pickupTime.split('==')[1];

    if (this.CartTotal < minimumOrderPrice) {
      window.alert('Η ελάχιστη παραγγελία είναι ' + minimumOrderPrice + ' €');
      return;
    }
    const order = {
      ...value,
      address: localStorage.getItem('addressId'),
      deliveryFee: this.selectedStore.deliveryFee,
      storeAddress: this.selectedStore.address,
      pickupTime,
      pickupDate: moment(value.pickupDay).format('DD/MM/YYYY'),
      deliveryDate: moment(value.deliveryDay).format('DD/MM/YYYY'),
    };

    this.orderService.createOrder(order).subscribe((order: any) => {
      console.log(order);
      if (order.paymentType != 'card') {
        localStorage.removeItem('localCart');
        localStorage.removeItem('cart_id');
        this.cartService.deleteCart();
        window.alert('Η παραγγελία σας πραγματοποιήθηκε με επιτυχία');

        this.router.navigate(['/categories']);
      } else {
        this.router.navigate(['/payment'], {
          queryParams: { amount: this.CartTotal, orderId: order.id },
        });
      }
    });
  }

  findOffset(items: CartItem[]) {
    let max = 1;
    items.map((item) => {
      item.extras.map((extra) => {
        if (extra.completionDays > max) {
          max = extra.completionDays;
        }
      });
    });
    return max;
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  editItem(category: string, name: string) {
    console.log(category, name);
    this.router.navigate(['/single'], {
      queryParams: {
        store: this.storeId,
        category,
        name,
        grouptype: this.groupType,
      },
    });
  }

  ngOnDestroy() {
    if (this.cartSubscription) [this.cartSubscription.unsubscribe()];
  }
  onNativeChange(e) {
    // if (this.profile.invoice.name !== 'onoma1') {
    //   console.log('onoma2');
    // }
    // if (this.profile.invoice.name === '') {
    console.log('onoma1');
    this.open(this.templateRef);
  }

  open(content, name?: string) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onSubmitInvoice(f: NgForm) {
    console.log(f.value);
    let token = localStorage.getItem('token');
    console.log(token);
    let userId = localStorage.getItem('userId');
    console.log(userId);

    console.log('here I am too');
    this.user
      .updateUserInvoice(
        token,
        userId,
        this.profile.firstName,
        this.profile.lastName,
        this.profile.phone,
        this.profile.email,
        f.value.name,
        f.value.afm,
        f.value.doy,
        f.value.email,
        f.value.job,
        f.value.phone,
        f.value.address
      )
      .subscribe((data) => {
        console.log(data);
      });

    this.modalService.dismissAll(); //dismiss the modal
  }
}
