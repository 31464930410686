<div class="containerBox" *ngIf="profile">
  <div class="outerBox">
    <div class="label2">Στοιχεία Προφίλ:</div>
    <div class="label">Όνομα:</div>
    <div class="infoBox">{{ profile.firstName }}</div>
    <div class="label">Επίθετο:</div>
    <div class="infoBox">{{ profile.lastName }}</div>
    <div class="label">Τηλέφωνο:</div>
    <div class="infoBox">{{ profile.phone }}</div>
    <div class="label">E-mail:</div>
    <div class="infoBox">{{ profile.email }}</div>

    <div class="label">
      <button
        class="btn btn-outline-light blue-background"
        id="stores"
        (click)="open(content)"
      >
        Επεξεργασία Προφίλ
      </button>
    </div>
  </div>

  <div class="outerBox">
    <div class="label2">Στοιχεία Τιμολογίου:</div>
    <div class="label">Όνομα:</div>
    <div class="infoBox">{{ profile.invoice.name }}</div>
    <div class="label">ΑΦΜ:</div>
    <div class="infoBox">{{ profile.invoice.afm }}</div>
    <div class="label">ΔΟΥ:</div>
    <div class="infoBox">{{ profile.invoice.doy }}</div>
    <div class="label">E-mail:</div>
    <div class="infoBox">{{ profile.invoice.email }}</div>
    <div class="label">Διευθυνση:</div>
    <div class="infoBox">{{ profile.invoice.address }}</div>
    <div class="label">Επάγγελμα:</div>
    <div class="infoBox">{{ profile.invoice.job }}</div>
    <div class="label">Τηλέφωνο</div>
    <div class="infoBox">{{ profile.invoice.phone }}</div>

    <div class="label">
      <button
        class="btn btn-outline-light blue-background"
        id="stores"
        (click)="open(invoice)"
      >
        Επεξεργασία Τιμολογίου
      </button>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Επεξεργασία Προφίλ</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #f="ngForm" novalidate>
      <div class="form-group">
        <label class="d-block" for="name">Όνομα</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          class="form-control"
          [(ngModel)]="profile.firstName"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block" for="name">Επίθετο</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          class="form-control"
          [(ngModel)]="profile.lastName"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block" for="name">Τηλέφωνο</label>
        <input
          type="text"
          id="phone"
          name="phone"
          class="form-control"
          [(ngModel)]="profile.phone"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block" for="name">E-mail</label>
        <input
          type="text"
          id="email"
          name="email"
          class="form-control"
          [(ngModel)]="profile.email"
          required
        />
      </div>
      <div class="text-center">
        <button
          data-dismiss="modal"
          class="btn btn-info"
          type="button"
          (click)="onSubmitProfile(f)"
          [disabled]="f.invalid"
        >
          Αποθήκευση
        </button>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Ακύρωση
    </button>
  </div>
</ng-template>
<ng-template #invoice let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Επεξεργασία Τιμολογίου</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #finv="ngForm" novalidate>
      <div class="form-group">
        <label class="d-block" for="name">Όνομα</label>
        <input
          type="text"
          id="name"
          name="name"
          class="form-control"
          [(ngModel)]="profile.invoice.name"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block" for="name">ΑΦΜ</label>
        <input
          type="text"
          id="afm"
          name="afm"
          class="form-control"
          [(ngModel)]="profile.invoice.afm"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block" for="name">ΔΟΥ</label>
        <input
          type="text"
          id="doy"
          name="doy"
          class="form-control"
          [(ngModel)]="profile.invoice.doy"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block" for="name">E-mail</label>
        <input
          type="text"
          id="email"
          name="email"
          class="form-control"
          [(ngModel)]="profile.invoice.email"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block" for="name">Διευθυνση</label>
        <input
          type="text"
          id="address"
          name="address"
          class="form-control"
          [(ngModel)]="profile.invoice.address"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block" for="name">Επάγγελμα</label>
        <input
          type="text"
          id="job"
          name="job"
          class="form-control"
          [(ngModel)]="profile.invoice.job"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block" for="name">Tηλεφωνο</label>
        <input
          type="text"
          id="phone"
          name="phone"
          class="form-control"
          [(ngModel)]="profile.invoice.phone"
          required
        />
      </div>

      <div class="text-center">
        <button
          data-dismiss="modal"
          class="btn btn-info"
          type="button"
          (click)="onSubmitInvoice(finv)"
          [disabled]="finv.invalid"
        >
          Αποθήκευση
        </button>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Ακύρωση
    </button>
  </div>
</ng-template>
