<section>
  <div
    id="carouselExampleSlidesOnly"
    class="carousel-slide"
    data-ride="carousel"
  >
    <div class="carousel-inner zAxis">
      <div class="carousel-item active">
        <img
          class="d-block w-100"
          src="../../../assets/carousel1.png
        "
          alt="First slide"
        />
        <div
          class="carousel-caption d-none d-md-block"
          [ngClass]="{ onStores: showBanner }"
        >
          <div class="form-check" *ngFor="let store of stores; let i = count">
            <div>
              <div>
                <h5>{{ getServiceCount(i) }}</h5>
              </div>
            </div>
          </div>
          <h5 *ngIf="showBanner" id="text">
            Βρέθηκαν {{ this.count }} καταστήματα
          </h5>
          <button
            class="btn btn-outline-light blue-background white"
            (click)="leave()"
          >
            {{ currentAddress }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
