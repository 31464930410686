<div class="filters">
  <form>
    <p class="txt">Φίλτρα</p>

    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="defaultCheck1"
      />
      <label class="form-check-label" for="defaultCheck1">
        Special offers
      </label>
    </div>

    <p class="txt">Υπηρεσίες</p>
    <!-- <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="allservices"
      />
      <label class="form-check-label" for="allservices">
        Όλες οι υπηρεσίες
      </label>
    </div> -->

    <div class="filtersChck">
      <div class="form-check" *ngFor="let storeGroup of storeGroups">
        <div *ngIf="storeGroup.id === groupId">
          <div
            *ngFor="let key of getKeys(storeGroup.services); let index = index"
          >
            <input
              class="form-check-input"
              type="checkbox"
              [value]="storeGroup.services[key]"
              [id]="index"
              (change)="onChange($event)"
            />
            <label class="form-check-label" [for]="index">
              {{ storeGroup.services[key] }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="form-submit">
      <button
        type="button"
        class="btn btn-light blue-background white"
        (click)="onSubmit()"
      >
        Εφαρμογή
      </button>
    </div>
  </form>
</div>
