<head>
  <style>
    @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;500;700;800&display=swap");
  </style>
</head>
<div>
  <div
    class="breakpoint"
    style="background-color: rgba(179, 179, 179, 0.2)"
  ></div>

  <div id="img" class="position-relative">
    <img id="photof" src="../../../assets/Footer_empty.png" />
  </div>
</div>

<!-- Footer -->
<footer class="text-center text-lg-start" id="footer">
  <!-- Grid container -->
  <div class="container p-4">
    <!--Grid row-->
    <div class="row">
      <!--Grid column-->
      <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
        <h5 id="txt">e-cleaning</h5>

        <p>
          Ονειρευτήκαμε έναν κόσμο χωρίς χαμένο χρόνο! Ένα κόσμο όπου τα
          Καθαριστήρια ρούχων, Ταπητοκαθαριστήρια, Πλυντήρια Αυτοκινήτων &
          Συνεργεία Βιολογικού Καθαρισμού βρίσκονται όλα μέσα σε ένα app!
        </p>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div style="padding-top: 1rem" class="col-lg-2 col-md-6 mb-4 mb-md-0">
        <ul class="list-unstyled mb-0">
          <li>
            <a [routerLink]="['/settings', 'about-us']">Ποιοί είμαστε</a>
          </li>
          <li>
            <a [routerLink]="['/settings', 'how-it-works']">Πώς λειτουργεί</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/settings', 'services']">Υπηρεσίες</a>
          </li> -->

          <!-- <li>
            <a [routerLink]="['/settings', 4]">Blog</a>
          </li> -->
        </ul>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div style="padding-top: 1rem" class="col-lg-2 col-md-6 mb-4 mb-md-0">
        <ul class="list-unstyled">
          <!-- <li>
            <a [routerLink]="['/settings', 5]">Επικοινωνία</a>
          </li> -->
          <!-- <li>
            <a [routerLink]="['/settings', 6]">Έχεις κατάστημα;</a>
          </li> -->
          <li>
            <a [routerLink]="['/settings', 'payment-types']">Τρόποι Πληρωμής</a>
          </li>
          <li>
            <a [routerLink]="['/settings', 'oroi']">Όροι χρήσης</a>
          </li>
        </ul>
      </div>
      <!--Grid column-->
      <!--Grid column-->
      <div style="padding-top: 1rem" class="col-lg-2 col-md-6 mb-4 mb-md-0">
        <ul class="list-unstyled">
          <li>
            <a [routerLink]="['/settings', 'politiki-aporr']"
              >Πολιτική απορρήτου</a
            >
          </li>
          <li>
            <a [routerLink]="['/settings', 'faqs']">FAQs</a>
          </li>
          <!-- <li>
            <a [routerLink]="['/settings', 'politiki-prostasias']"
              >Πολιτική προστασίας</a
            >
          </li>
          <li>
            <a [routerLink]="['/settings', 'politiki-cookies']"
              >Πολιτική cookies</a
            >
          </li>
          <li>
            <a [routerLink]="['/settings', 'politiki-aksiologisis']"
              >Πολιτική αξιολόγησης</a
            >
          </li> -->
        </ul>
      </div>
      <!--Grid column-->
    </div>
    <div class="socials">
      <a href="https://www.facebook.com/ecleaningapp">
        <img id="socImg" src="../../../assets/svgWeb/facebook (2).svg" />
      </a>
      <a href="https://www.instagram.com/ecleaningapp/">
        <img
          id="socImg"
          src="../../../assets/svgWeb/instagram-sketched (2).svg"
        />
      </a>
    </div>
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div id="copyright" class="text-center p-3">
    Powered by SOFTWeb - Adaptive I.T. Solutions // © Copyright e-cleaning 2021
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
