import { MapsAPILoader } from '@agm/core';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { ICart, ICartTotals } from 'src/app/stores/models/Cart';
import { IReviewStore } from 'src/app/stores/models/ReviewStore';
import { ISingleStore } from 'src/app/stores/models/singleStore';
import { IStore } from 'src/app/stores/models/store';
import { CartService } from 'src/app/stores/services/cart.service';
import { RequestsService } from 'src/app/stores/services/requests.service';

@Component({
  selector: 'app-content-store',
  templateUrl: './content-store.component.html',
  styleUrls: ['./content-store.component.css'],
})
export class ContentStoreComponent implements OnInit, OnDestroy {
  stores: IStore[] = [];
  store: IStore;
  singleStore: ISingleStore[] = [];
  singleStoreSquare: any[] = [];
  singleStoreSquare$ = new BehaviorSubject<any>(null);
  storeReviews: IReviewStore[] = [];
  selectedCategory: string;
  selectedCategoryName: string;
  storeId: string;

  public activePillIndex: number = 0;
  showHide: Boolean = true;
  itemName: string;
  serviceName: string;
  extraPrice: number;
  itemSum: number = 0;
  itemQnt: number = 0;
  extraQnt: number = 0;
  cart$: Observable<ICart>;
  CartTotal: number = 0;
  subTotal: number = 0;
  cartTotal$: Observable<ICartTotals>;
  @ViewChild('vpillstab', { read: ElementRef }) public pills: ElementRef<any>;
  @ViewChild('content') content: ElementRef<any>;
  cartSubscription: Subscription;

  //map

  lat: 40.63232868179058;
  lng: 22.94083684050897;

  zoom = 18;
  private geoCoder;
  inviteMap: any = { card: 'Κάρτα', cash: 'Μετρητά', pos: 'POS' };
  inviteMap2: any = {
    'square-meter': 'τ.μ.',
    'per-meter': 'μέτρο',
    tmx: 'τμχ',
    kg: 'κιλό',
    vehicle: 'όχημα',
  };
  isDisabled: Boolean = true;
  closeResult: string;

  name: string;
  key: number;
  key2: number;
  modalVariable: string;

  grouptype: string;

  constructor(
    private storeReq: RequestsService,
    private cartService: CartService,
    private route: ActivatedRoute,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.storeId = params.get('store');
      this.selectedCategory = params.get('category');
      this.selectedCategoryName = params.get('name');
      this.grouptype = params.get('grouptype');

      this.storeReq
        .getStoreById()
        .pipe(
          map((res) => {
            console.log('**************', res);
            return res.find((store) => store.id === this.storeId);
          }),
          mergeMap((store) =>
            store ? of(store) : this.storeReq.getStoreInfo(this.storeId)
          )
        )
        .subscribe((res) => {
          this.store = res;
        });
    });
    /*  console.log(this.storeId); */

    /*  console.log(this.stores); */

    this.storeReq.getSingleStorebyId(this.storeId).subscribe((data) => {
      this.singleStore = data;
      console.log('katasstima', data);
      this.cartSubscription = this.cartService.cart$
        .pipe(take(1))
        .subscribe((newCart) => {
          console.log('new Cart', newCart);
          if (newCart?.storeId != this.storeId) {
            this.cartService.deleteCart();
            return;
          }
          newCart?.items.forEach((item) => {
            if (item.type === 'square-meter') {
              item.extras.map((it) => {
                this.singleStoreSquare = this.singleStoreSquare.concat({
                  price: item.price,
                  category: item.category,
                  categoryName: item.category,
                  quantity: 0,
                  type: item.type,
                  name: item.name,
                  extras: it,
                });
              });

              return;
            }
            const categoryIndex = this.singleStore.findIndex(
              (k) => k.name === item.category
            );

            if (categoryIndex == -1) {
              return;
            }

            if (this.selectedCategory === item.category) {
              this.selectPill(categoryIndex);
            }
            const itemIndex = this.singleStore[
              categoryIndex
            ].consumables?.findIndex((con) => {
              // console.log('con.name', con.name, item.name);
              return con.name === item.name;
            });

            // console.log('itemIndex', itemIndex);
            if (itemIndex == -1) {
              return;
            }
            if (this.selectedCategoryName === item.name) {
              this.showHideContent(
                this.selectedCategory,
                this.selectedCategoryName
              );
            }

            item.extras.forEach((extra) => {
              const extraIndex = this.singleStore[categoryIndex].consumables[
                itemIndex
              ].extras.findIndex((ex) => ex.name === extra.name);

              // console.log('extraIndex', extraIndex);
              if (extraIndex == -1) {
                return;
              }

              // console.log('I found index');

              this.singleStore[categoryIndex].consumables[itemIndex].extras[
                extraIndex
              ].quantity = extra.quantity;

              this.singleStore[categoryIndex].consumables[itemIndex].quantity =
                item.quantity;
            });
          });

          console.log('new Cart 2', this.singleStore);
        });
    });

    this.storeReq
      .getStoreReview(this.storeId)
      .subscribe((data) => (this.storeReviews = data));
    /*    console.log(this.storeReviews); */

    this.cart$ = this.cartService.cart$;
    this.cartTotal$ = this.cartService.cartTotal$;
  }

  getKeys(obj: any): Array<string> {
    return Object.keys(obj);
  }

  public selectPill(index: number) {
    this.activePillIndex = index;
    // do some other stuff if necessary...
  }

  public scrollRight(): void {
    this.pills.nativeElement.scrollTo({
      left: this.pills.nativeElement.scrollLeft + 310,
      behavior: 'smooth',
    });
    /*   console.log('Scroll DU'); */
  }

  public scrollLeft(): void {
    this.pills.nativeElement.scrollTo({
      left: this.pills.nativeElement.scrollLeft - 310,
      behavior: 'smooth',
    });
  }

  showHideContent(
    nameCategory?: any,
    nameConsumable?: any,

    categoryIndex?: number,
    type?: string,
    content?: any
  ) {
    window.scroll({
      top: 250,

      behavior: 'smooth',
    });
    this.showHide = this.showHide ? false : true;
    this.activePillIndex = 0;
    this.itemName = nameConsumable;
    this.serviceName = nameCategory;

    if (categoryIndex) {
      this.selectPill(categoryIndex);
    }
    console.log(type);
    if (type === 'square-meter') {
      console.log(type);

      this.open(content, type);
    }
  }

  /*   addNumbers(base:number, extra:number){
    
    let total = +base + +extra;
    /* console.log(total); 
    
    return this.extraPrice = total;
    

   }
 */

  cartView(name: string) {
    for (let i = 0; i < this.singleStore.length; i++) {
      this.addItemToCart(i);
    }
    this.showHideContent(null, null);
    this.getTotals();
    this.cartPreview();
  }
  getTotals(sq?: any) {
    let priceSum = 0;
    const cart = this.cartService.getCurrentCartValue();
    console.log(sq?.length);
    if (cart === null || sq?.length === 0) {
      this.CartTotal = 0;
      this.isDisabled = true;
      if (localStorage.getItem('cart_id')) {
        localStorage.removeItem('localCart');
        localStorage.removeItem('cart_id');
        this.cartService.deleteCart();
      }
    } else {
      console.log('run');
      cart.items.forEach((element) => {
        element.extras.forEach((extra) => {
          priceSum +=
            (element.type === 'square-meter'
              ? extra.length * extra.width
              : extra.quantity) * Number(extra.price);
        });
        this.CartTotal = priceSum;
        /*  console.log('sum', priceSum);
        console.log(element);
        console.log('checkme2'); */
      });
    }
    console.log(this.CartTotal);
    cart.storeId = this.storeId;
    cart.totalPrice = this.CartTotal;
    console.log(cart.totalPrice);

    /*  const subTotal = cart.items[key]?.extras.reduce((a,b) => (b.price * b.quantity)+a, 0);
        console.log(subTotal);
       
      this.subTotal = subTotal;
      return this.subTotal;
      this.CartTotal += subTotal;
       cart.totalPrice = this.CartTotal 
     
      
      console.log(cart.totalPrice ) */

    /*   this.cartService.calculateTotals(this.CartTotal,this.subTotal)
      
      this.cartTotal$.subscribe(data=>{
        console.log("data from cartTOTAL!",data);
      }) */

    /*   const total = cart.items[key]?.extras.reduce((a,b) => (b.price * b.quantity)+a, 0);
      this.CartTotal = total;
      console.log(total);
      return total; */
  }

  addtotal(
    name: string,
    key: number,
    key2: number,
    width?: number,
    length?: number
  ) {
    console.log('iamheretoo', name, key, key2, width, length);
    for (let i = 0; i < this.singleStore.length; i++) {
      if (this.singleStore[i].name == name) {
        if (this.singleStore[i].consumables[key].type === 'square-meter') {
          console.log('tetragwnika', width);
          let newIt = {
            categoryName: name,
            price: this.singleStore[i].consumables[key].price,
            category: this.singleStore[i].consumables[key].category,
            quantity: this.singleStore[i].consumables[key].quantity,
            type: this.singleStore[i].consumables[key].type,
            name: this.singleStore[i].consumables[key].name,
            extras: {
              completionDays:
                this.singleStore[i].consumables[key].extras[key2]
                  .completionDays,
              price: this.singleStore[i].consumables[key].extras[key2].price,
              quantity: 1,
              name: this.singleStore[i].consumables[key].extras[key2].name,
              width: width,
              length: length,
            },
          };
          if (newIt.extras.width && newIt.extras.length != 0) {
            this.singleStoreSquare = this.singleStoreSquare.concat({
              ...newIt,
            });
          } else {
            return;
          }

          console.log('look here!!!', this.singleStoreSquare);

          let totalPrice =
            this.singleStore[i].consumables[key].extras[key2].price;
          let itemPrice =
            this.singleStore[i].consumables[key].extras[key2].width *
            this.singleStore[i].consumables[key].extras[key2].length *
            totalPrice;
          this.itemSum = itemPrice;
        } else {
          console.log(
            'add 1',
            name,
            key,
            key2,
            this.singleStore[i].consumables[key].quantity,
            this.singleStore[i].consumables[key].extras[key2].quantity
          );
          console.log(this.singleStore[i].consumables[key].extras[key2].price);
          this.singleStore[i].consumables[key].quantity++;
          this.singleStore[i].consumables[key].extras[key2].quantity++;

          console.log(
            name,
            this.singleStore[i].consumables[key].quantity,
            this.singleStore[i].consumables[key].extras[key2].quantity
          );

          let totalPrice =
            this.singleStore[i].consumables[key].extras[key2].price;
          /*  console.log(totalPrice); */

          let itemPrice =
            totalPrice *
            this.singleStore[i].consumables[key].extras[key2].quantity;
          this.itemSum = itemPrice;

          this.itemQnt =
            this.singleStore[i].consumables[key].extras[key2].quantity;
        }

        /*  console.log(this.singleStore[i].consumables[key].quantity);
        console.log(this.singleStore[i].consumables[key].extras[key2].quantity); */
      }
    }
    /*     console.log('price', this.itemSum);
    console.log('qnt', this.itemQnt); */
  }

  removeSq(index: number, categoryIndex) {
    console.log('categoryIndex', categoryIndex);
    for (let i = 0; i < this.singleStoreSquare.length; i++) {
      if (i === index) {
        this.singleStoreSquare.splice(index, 1);
      }
      console.log(this.singleStoreSquare);
    }

    this.addItemToCart(categoryIndex);
    this.getTotals(this.singleStoreSquare);
  }
  removetotal(
    name: string,
    key: number,
    key2: number,
    width?: number,
    length?: number
  ) {
    console.log('---------------------------');
    for (let i = 0; i < this.singleStore.length; i++) {
      if (this.singleStore[i].name == name) {
        if (this.singleStore[i].consumables[key].type === 'square-meter') {
          console.log('tetragwnika', width);
          this.singleStore[i].consumables[key].quantity = 1;
          this.singleStore[i].consumables[key].extras[key2].quantity = 1;
          if (this.singleStore[i].consumables[key].extras[key2].width) {
            this.singleStore[i].consumables[key].extras[key2].width -= width;
            this.singleStore[i].consumables[key].extras[key2].length -= length;
            if (this.singleStore[i].consumables[key].extras[key2].width < 0) {
              this.singleStore[i].consumables[key].extras[key2].width = 0;
            }
            if (this.singleStore[i].consumables[key].extras[key2].length < 0) {
              this.singleStore[i].consumables[key].extras[key2].length = 0;
            }
          }

          console.log(this.singleStore[i].consumables[key].extras[key2].width);
          let totalPrice =
            this.singleStore[i].consumables[key].extras[key2].price;
          let itemPrice =
            this.singleStore[i].consumables[key].extras[key2].width *
            this.singleStore[i].consumables[key].extras[key2].length *
            totalPrice;
          this.itemSum = itemPrice;
        } else {
          /*        console.log(name, key, key2);
        console.log(this.singleStore[i].consumables[key].extras[key2].price); */
          this.singleStore[i].consumables[key].quantity--;
          this.singleStore[i].consumables[key].extras[key2].quantity--;

          let totalPrice =
            this.singleStore[i].consumables[key].extras[key2].price;
          /* console.log(totalPrice); */

          let itemPrice =
            totalPrice *
            this.singleStore[i].consumables[key].extras[key2].quantity;
          this.itemSum = itemPrice;

          this.itemQnt =
            this.singleStore[i].consumables[key].extras[key2].quantity;

          if (this.itemSum < 0) {
            this.itemSum = 0;
          }
          if (this.itemQnt < 0) {
            this.itemQnt = 0;
          }
          if (this.singleStore[i].consumables[key].extras[key2].quantity < 0) {
            this.singleStore[i].consumables[key].extras[key2].quantity = 0;
          }
          if (this.singleStore[i].consumables[key].quantity < 0) {
            this.singleStore[i].consumables[key].quantity = 0;
          }
        }
        /* console.log(this.singleStore[i].consumables[key].quantity);
        console.log(this.singleStore[i].consumables[key].extras[key2].quantity); */
      }
    }
  }

  addItemToCart(num: number) {
    this.cartService.addItemtoCart(
      this.singleStore[num],
      this.singleStoreSquare
    );
  }

  cartPreview() {
    /*  console.log(this.cartService.getCurrentCartValue()); */
    const cart = this.cartService.getCurrentCartValue();
    if (cart != null) {
      this.isDisabled = false;
      // console.log(cart.items[0].name);
    }

    /* console.log(this.cart$) */

    /* this.cart$.subscribe(data=>{
    console.log("data from cart!",data);
  }) */
  }

  addToCart(
    key1: number,
    key2: number,
    key3: number,
    _Sum: number,
    Qnt: number,
    Store: IStore[]
  ) {}

  checkout() {
    const cart = this.cartService.getCurrentCartValue();
    let myJSON = JSON.stringify(cart);
    localStorage.setItem('localCart', myJSON);
    this.router.navigate([
      '/checkout',
      { store: this.storeId, grouptype: this.grouptype },
    ]);
  }

  goBack() {
    let groupId = localStorage.getItem('storeGroupId');
    this.router.navigate(['/store'], { queryParams: { storegroup: groupId } });
  }

  ngOnDestroy() {
    if (this.cartSubscription) [this.cartSubscription.unsubscribe()];
  }

  open(content, name: string) {
    this.name = name;

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onSubmit(f: NgForm, name: string, key: number) {
    console.log(f.value);
    console.log(f.value.length);
    let width = f.value.width;

    console.log(f.value.width);
    console.log(f.value.option);
    console.log('here I am', name, key);

    this.addtotal(name, key, f.value.option, width, f.value.length);

    this.modalService.dismissAll(); //dismiss the modal
  }
}
