import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IStoreGroups } from 'src/app/stores/models/storeGroups';
import { RequestsService } from 'src/app/stores/services/requests.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
})
export class NavComponent implements OnInit {
  storeGroups: IStoreGroups[] = [];
  groupId: string;
  selectedFilter: string[] = [];

  constructor(
    private groupReq: RequestsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.groupReq.updateFilters(this.selectedFilter);

    this.route.queryParamMap.subscribe((params) => {
      this.groupId = params.get('storegroup');
    });

    this.groupReq
      .getStoreGroups()
      .subscribe((data) => (this.storeGroups = data));
  }

  onChange(event) {
    this.selectedFilter = this.selectedFilter.includes(event.target.value)
      ? this.selectedFilter.filter((item) => item != event.target.value)
      : this.selectedFilter.concat(event.target.value);
  }

  onSubmit() {
    this.groupReq.updateFilters(this.selectedFilter);
    this.groupReq.onSearch();
  }

  getKeys(obj: any): Array<string> {
    return Object.keys(obj);
  }
}
