import uuid from 'uuid/v4';

export interface ICart {
  storeId?: string;
  totalPrice?: number;
  items: CartItem[];
}

export interface CartItem {
  price: number;
  category: string;
  quantity: number;
  type: string;
  name: string;
  extras: Extra[];
}

export interface Extra {
  completionDays?: number;
  price: number;
  quantity: number;
  name: string;
  width?: number;
  length?: number;
}

export interface ICartTotals {
  /* [name:string]:string; */
  total: number;
  subtotal: number;
}

export class Cart implements ICart {
  id = uuid();
  items: CartItem[] = [];
}
