<!-- <app-carousel></app-carousel> -->

<div id="store" *ngFor="let store of stores">
  <div class="storeView" *ngIf="store.id === storeId">
    <div class="container2">
      <div id="left">
        <img
          id="storeImg"
          class="d-block"
          [src]="store.imgUrl"
          alt="Store Image"
        />
      </div>

      <div id="right">
        <div>
          <span id="text2"> {{ store.name }}</span>
        </div>

        <div>
          <div id="rateStar">
            <div>
              <img
                id="flexImg"
                src="../../../assets/svgWeb/Icon ionic-ios-star.svg"
                alt="Stars"
              />
            </div>
            <div class="blueRating">({{ store.ratingsCount }})</div>
            <div class="greyRating">({{ store.averageQualityRating }})</div>
          </div>
        </div>
        <div id="text3">{{ store.address }}</div>
        <div id="text">{{ store.storeDescription }}</div>
      </div>
    </div>
  </div>
</div>

<div class="cartView">
  <div class="block">
    <div
      id="row"
      *ngFor="
        let cart of this.localCart?.items;
        let index = index;
        let o = odd;
        let e = even
      "
      [ngClass]="{ odd: o, even: e }"
      class="p-2"
    >
      <div class="cartTxtFlex" (click)="editItem(cart.category, cart.name)">
        <div class="cartItem">
          <div class="text10">{{ cart.name }}</div>
          <div class="text11">{{ cart.category }}</div>
        </div>

        <div class="text12">x{{ cart.quantity }}</div>
      </div>
    </div>
  </div>

  <div class="block2 mt-4 px-2">
    <div id="text4">Έξοδα παράδοσης {{ selectedStore?.deliveryFee }} €</div>
    <div id="text4">
      Σύνολο: {{ (cart$ | async)?.totalPrice?.toFixed(2) }} €
    </div>
    <div class="spacer"></div>
  </div>

  <div class="spacer"></div>

  <form class="w-100 px-2" [formGroup]="checkoutForm">
    <div
      class="dates"
      [ngClass]="{ hideDivider: groupType === 'single-calendar' }"
    >
      <div class="pickup">
        <div id="pickup">
          <img class="d-block" src="../../../assets/svgWeb/Group 288.svg" />
          <mat-label>{{
            groupType === "double-calendar" ? "Παραλαβή" : "Ραντεβού"
          }}</mat-label>
        </div>

        <div>
          <mat-form-field>
            <mat-label
              >Ημερ/νία
              {{
                groupType === "double-calendar" ? "Παραλαβής" : "Ραντεβού"
              }}</mat-label
            >
            <input
              formControlName="pickupDay"
              matInput
              [matDatepickerFilter]="myFilter2"
              [matDatepicker]="dp4"
              (click)="dp4.open()"
              (dateChange)="
                popDropdownHoursPickup(checkoutForm.get('pickupDay').value)
              "
              readonly
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dp4"
            ></mat-datepicker-toggle>
            <mat-datepicker #dp4 disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>

        <select formControlName="pickupTime" class="select">
          <option value="null">Ώρα</option>
          <option
            *ngFor="
              let hour of storeCalendar?.pickUpDaysCalendar[pickupDateIndex]
                ?.hours
            "
            value="{{ hour.time + '==' + hour.minimumOrderPrice }}"
          >
            {{ hour.time }}
          </option>
        </select>
      </div>

      <div class="delivery" *ngIf="groupType === 'double-calendar'">
        <div id="delivery">
          <img class="d-block" src="../../../assets/svgWeb/Group 289.svg" />
          <mat-label>Παράδοση</mat-label>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Ημερ/νία Παράδοσης</mat-label>
            <input
              formControlName="deliveryDay"
              matInput
              [matDatepickerFilter]="myFilter"
              [matDatepicker]="dp3"
              (click)="dp3.open()"
              (dateChange)="
                popDropdownHours(checkoutForm.get('deliveryDay').value)
              "
              [min]="minimuPickupDate"
              readonly
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dp3"
              [disabled]="!checkoutForm.get('pickupDay').value"
            ></mat-datepicker-toggle>
            <mat-datepicker #dp3 disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>

        <select formControlName="deliveryTime" class="select">
          <option value="null">Ώρα</option>
          <option
            *ngFor="
              let hour of storeCalendar?.deliveryDaysCalendar[deliveryDateIndex]
                ?.hours
            "
            value="{{ hour.time }}"
          >
            {{ hour.time }}
          </option>
        </select>
      </div>
    </div>

    <div class="spacer"></div>

    <div class="block3">
      <div id="store" *ngFor="let store of stores">
        <div class="payments" *ngIf="store.id === storeId">
          <p id="text5">Τροποι πληρωμής</p>

          <select formControlName="paymentType" class="select">
            <option
              *ngFor="let paymentType of store.paymentTypes"
              value="{{ paymentType }}"
              selected="{{ paymentType }}"
            >
              {{ paymentType | i18nSelect: inviteMap }}
            </option>
          </select>
        </div>
      </div>

      <div class="invoice" class="select d-flex align-items-center">
        <input
          formControlName="issueInvoice"
          type="checkbox"
          (change)="onNativeChange($event)"
          id="forcheckbox"
        />
        <label class="checkmark" for="forcheckbox"> Έκδοση τιμολογίου</label>
      </div>
      <div></div>
    </div>

    <div class="spacer"></div>
    <div class="spacer"></div>
    <p id="text5">Σχόλια</p>
    <div class="txtarea">
      <textarea
        formControlName="comments"
        id="w3review"
        name="w3review"
        rows="4"
        cols="50"
      >
      </textarea>
    </div>
  </form>
  <div class="spacer"></div>
  <div class="spacer"></div>
  <div class="px-2">
    <button
      type="button"
      class="btn btn-outline-light blue-background white"
      [disabled]="checkoutForm.invalid"
      (click)="onSubmit(checkoutForm.value)"
    >
      Αποστολή Παραγγελίας
    </button>
  </div>

  <div class="spacer"></div>
  <div class="spacer"></div>
</div>

<ng-template #invoice let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Επεξεργασία Τιμολογίου</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #finv="ngForm" novalidate>
      <div class="form-group">
        <label class="d-block invoice-label" for="name">Όνομα</label>
        <input
          type="text"
          id="name"
          name="name"
          class="form-control"
          [(ngModel)]="profile.invoice.name"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block invoice-label" for="name">ΑΦΜ</label>
        <input
          type="text"
          id="afm"
          name="afm"
          class="form-control"
          [(ngModel)]="profile.invoice.afm"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block invoice-label" for="name">ΔΟΥ</label>
        <input
          type="text"
          id="doy"
          name="doy"
          class="form-control"
          [(ngModel)]="profile.invoice.doy"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block invoice-label" for="name">E-mail</label>
        <input
          type="text"
          id="email"
          name="email"
          class="form-control"
          [(ngModel)]="profile.invoice.email"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block invoice-label" for="name">Διευθυνση</label>
        <input
          type="text"
          id="address"
          name="address"
          class="form-control"
          [(ngModel)]="profile.invoice.address"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block invoice-label" for="name">Επάγγελμα</label>
        <input
          type="text"
          id="job"
          name="job"
          class="form-control"
          [(ngModel)]="profile.invoice.job"
          required
        />
      </div>
      <div class="form-group">
        <label class="d-block invoice-label" for="name">Tηλεφωνο</label>
        <input
          type="text"
          id="phone"
          name="phone"
          class="form-control"
          [(ngModel)]="profile.invoice.phone"
          required
        />
      </div>

      <div class="text-center">
        <button
          data-dismiss="modal"
          class="btn btn-info"
          type="button"
          (click)="onSubmitInvoice(finv)"
          [disabled]="finv.invalid"
        >
          Αποθήκευση
        </button>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Ακύρωση
    </button>
  </div>
</ng-template>
