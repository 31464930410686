import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICart, ICartTotals } from 'src/app/stores/models/Cart';
import { CartService } from 'src/app/stores/services/cart.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
cart$:Observable<ICart>;
cartTotal$: Observable<ICartTotals>;

  constructor(private cartService: CartService) { }

  ngOnInit(): void {
    this.cart$ = this.cartService.cart$;
    
  }

}
