import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IUser } from 'src/app/stores/models/User';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  profile: IUser;
  closeResult: string;
  phoneCode = '+30';
  phoneFull = '';
  constructor(private user: UserService, private modalService: NgbModal) {}

  ngOnInit(): void {
    let token = localStorage.getItem('token');
    console.log(token);
    let user = localStorage.getItem('userId');
    console.log(user);
    this.user.getUserProfile(token, user).subscribe((data) => {
      this.profile = data;
      console.log(this.profile);
    });
  }
  open(content, name?: string) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onSubmitProfile(f: NgForm) {
    console.log(f.value);
    let token = localStorage.getItem('token');
    console.log(token);
    let userId = localStorage.getItem('userId');
    console.log(userId);
    let str = '+30';
    console.log(f.value.phone.startsWith(str));
    if (!f.value.phone.startsWith(str)) {
      let string1 = f.value.phone;
      this.phoneFull = this.phoneCode.concat(string1);
      f.value.phone = this.phoneFull;
      console.log(f.value.phone);
    }

    console.log('here I am');
    this.user
      .updateUserProfile(
        token,
        userId,
        f.value.firstName,
        f.value.lastName,
        f.value.phone,
        f.value.email
      )
      .subscribe((data) => {
        console.log(data);
      });

    this.modalService.dismissAll(); //dismiss the modal
  }

  onSubmitInvoice(f: NgForm) {
    console.log(f.value);
    let token = localStorage.getItem('token');
    console.log(token);
    let userId = localStorage.getItem('userId');
    console.log(userId);

    console.log('here I am too');
    this.user
      .updateUserInvoice(
        token,
        userId,
        this.profile.firstName,
        this.profile.lastName,
        this.profile.phone,
        this.profile.email,
        f.value.name,
        f.value.afm,
        f.value.doy,
        f.value.email,
        f.value.job,
        f.value.phone,
        f.value.address
      )
      .subscribe((data) => {
        console.log(data);
      });

    this.modalService.dismissAll(); //dismiss the modal
  }
}
