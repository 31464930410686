import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IOrders } from 'src/app/stores/models/orders';
import { CartService } from 'src/app/stores/services/cart.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],
})
export class HistoryComponent implements OnInit {
  orders: IOrders[] = [];

  constructor(
    private user: UserService,
    private router: Router,
    private cartservice: CartService
  ) {}

  ngOnInit(): void {
    let token = localStorage.getItem('token');
    console.log(token);
    let user = localStorage.getItem('userId');
    console.log(user);

    this.user.getOrdersForUser(token, user).subscribe((data: any) => {
      this.orders = data;
      console.log(this.orders);
    });
  }

  onSelectOrder(order) {
    console.log(order);
    this.cartservice.setupCartFromHistory(
      order.items,
      order.totalPrice,
      order.store
    );
    this.router.navigate([`single`], {
      queryParams: {
        store: order.store,
        storegroup: order.storeInfo?.storegroup,
        grouptype: order.storegroupType,
      },
    });
  }
}
