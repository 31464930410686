import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  registerForm: FormGroup;
  phoneCode = '+30';

  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    if (localStorage.getItem('token')) {
      this.router.navigateByUrl('/categories');
    }
  }

  ngOnInit(): void {
    this.createRegisterForm();
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      email: [null, [Validators.required]],
    });
  }

  onSubmit() {
    let string1 = this.registerForm.value.phone;
    let phoneFull = this.phoneCode.concat(string1);
    this.registerForm.value.phone = phoneFull;

    this.authService.register(this.registerForm.value).subscribe(
      (data) => {
        console.log(data);
        this.router.navigateByUrl('/address');
        this.isSuccessful = true;
        this.isSignUpFailed = false;
      },
      (err) => {
        this.errorMessage = err.error.message;
        this.isSignUpFailed = true;
        if (this.errorMessage === 'Error signing up') {
          window.alert('Ο χρήστης με αυτό το κινητό υπάρχει ήδη');
        }
      }
    );
  }
}
