import { Pipe, PipeTransform } from '@angular/core';
import { IStore } from '../stores/models/store';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: IStore[],searchTerm: string){
    let filteredList=[];
    
    if(!searchTerm) {
      return items
    }
    
    filteredList = items.filter(store => store.name?.toUpperCase().trim().includes(searchTerm?.toUpperCase().trim()))

    return filteredList
  }
}
