<section>
  <div class="searchbox">
    <div class="form-inline">
      <input
        #searchinput
        class="form-control form-control-width w-75"
        type="search"
        placeholder="Αναζήτηση καταστήματος"
        aria-label="Search"
        (input)="onSearch(searchinput.value)"
      />
      <button
        class="btn btn-outline-light blue-background white text"
        type="button"
        (click)="onSearch(searchinput.value)"
      >
        Αναζήτηση
      </button>
    </div>
    <div>
      <div class="dropdown d-flex justify-content-between">
        <button
          class="btn btn-outline-light blue-background white mr-3"
          id="stores"
          (click)="goBack()"
        >
          Υπηρεσίες
        </button>

        <button
          class="btn btn-secondary light-background blue dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ dropdownItem }}
        </button>

        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a
            class="dropdown-item"
            *ngFor="let sortOption of sortOptions"
            (click)="sortBy(sortOption.id, sortOption.name)"
            >{{ sortOption.name }}</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex d-md-block flex-column align-items-center">
    <div *ngIf="stores.length === 0">
      <p class="comment">
        Δεν βρέθηκαν καταστήματα που να εξυπηρετούν αυτή τη διεύθυνση
      </p>
    </div>
    <div
      class="storeView"
      *ngFor="let store of stores | search: searchValue"
      [routerLink]="['/single']"
      [queryParams]="{ store: store.id, grouptype: grouptype }"
    >
      <div id="left">
        <img class="d-block storeImg" [src]="store.imgUrl" alt="Store Image" />
      </div>

      <div id="right">
        <div class="row1 d-flex align-items-center">
          <div>
            <span id="text2"> {{ store.name }}</span>
          </div>

          <div id="rateStar">
            <div>
              <img
                id="flexImg"
                src="../../../assets/svgWeb/Icon ionic-ios-star.svg"
                alt="Stars"
              />
            </div>
            <div class="blueRating">({{ store.ratingsCount }})</div>
            <div class="greyRating">({{ store.averageQualityRating }})</div>
          </div>
        </div>

        <div class="row2">
          <div class="innerFlex">
            <div class="roundedsquare">{{ store.minimumOrderPrice }}€</div>
            <!-- background image -->
            <!-- <div>  <span id="whiteText" class="centered"> {{store.minimumOrderPrice}}</span></div> -->
            <div id="liner">
              <p id="text">Ελάχ.</p>
              <p id="text">Παραγγελία</p>
            </div>
          </div>

          <div>
            <img
              id="flexImg"
              src="../../../assets/svgWeb/Icon awesome-truck.svg"
            />
            <span id="number"> {{ store.deliveryFee }}€ </span>
          </div>

          <div class="innerFlex">
            <div>
              <img id="flexImg" src="../../../assets/svgWeb/Layer 2.svg" />
            </div>

            <div id="liner">
              <p id="text">Κατόπιν</p>
              <p id="text">Ραντεβού</p>
            </div>
          </div>
        </div>

        <!-- <div *ngIf="store.hasSpecialOffer" class="row3">
          <div class="innerFlex">
            <div>
              <img
                id="flexImg"
                src="../../../assets/svgWeb/ic_new_releases_24px.svg"
              />
            </div>

            <div id="liner">
              <p id="text">Special</p>
              <p id="text">Offers</p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>
<!--  <div class="item" style="background: #00112233;">
    
    <span></span>
  </div>

  <div class="item" style="background: #00112233;"> <div id="text" *ngFor="let key of getKeys(store.workHours)">
  {{ store.workHours[key].title }}
  </div></div>
  <div class="item" style="background: #00112233;">
   
    <div id="text" *ngFor="let key of getKeys(store.workHours)">
      {{ store.workHours[key].content }} 
    </div>
  </div> -->
