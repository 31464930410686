import { Directive, Output, EventEmitter, Input, SimpleChange } from '@angular/core';

@Directive({
  selector: '[appOnCreate]'
})
export class OnCreateDirective {

  @Output() onCreate: EventEmitter<any>  = new EventEmitter<any>();
  constructor() {}
  ngOnInit() {    
console.log('done')
     this.onCreate.emit(); 
  } 
}
