import { BrowserModule } from '@angular/platform-browser';
import { Component, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { WrapperModule } from './wrapper/wrapper.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { WrapperComponent } from './wrapper/wrapper/wrapper.component';
import { ContentCategoryComponent } from './wrapper/content-category/content-category.component';
import { ContentStoreComponent } from './wrapper/content-store/content-store.component';
import { AddressPageComponent } from './wrapper/address-page/address-page.component';
import { LoginComponent } from './wrapper/login/login.component';
import { SignupComponent } from './wrapper/signup/signup.component';
import { FormsModule } from '@angular/forms';
import { AddressChoiceComponent } from './wrapper/address-choice/address-choice.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { CheckoutComponent } from './wrapper/checkout/checkout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginAuthGuard } from './login-auth.guard';
import { AuthGuard } from './auth.guard';
import { SearchPipe } from './pipes/search.pipe';
import { HistoryComponent } from './wrapper/history/history.component';
import { SettingsComponent } from './wrapper/settings/settings.component';
import { PaymentsComponent } from './wrapper/payments/payments.component';
import { ProfileComponent } from './wrapper/profile/profile.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingInterceptor } from './_interceptors/loading.interceptor';

const routes: Routes = [
  { path: 'store', component: WrapperComponent },
  { path: 'categories', component: ContentCategoryComponent },
  { path: 'single', component: ContentStoreComponent },
  { path: 'address', component: AddressChoiceComponent },
  { path: 'add', component: AddressPageComponent },
  { path: 'orders', component: HistoryComponent },
  { path: 'settings/:option', component: SettingsComponent },
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'signup', component: SignupComponent },
  { path: 'payment', component: PaymentsComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'checkout', component: CheckoutComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    WrapperModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: true,
    }),
    BrowserAnimationsModule,
    NgxSpinnerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    LoginAuthGuard,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
