import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAddress } from 'src/app/stores/models/address';
import { IStore } from 'src/app/stores/models/store';
import { IStoreGroups } from 'src/app/stores/models/storeGroups';
import { RequestsService } from 'src/app/stores/services/requests.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
})
export class BannerComponent implements OnInit {
  stores: IStore[] = [];

  addresses: IAddress[] = [];
  addressId: string;
  currentAddress: string;
  storeGroupId: string;
  groupId: string;
  services: string;
  count = 0;

  showBanner = false;
  postalCode: string;
  lat: string;
  lng: string;
  constructor(
    private storeReq: RequestsService,
    private user: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.groupId = params.get('storegroup');
    });
    this.postalCode = localStorage.getItem('postalCode');
    this.lat = localStorage.getItem('addressLat');
    this.lng = localStorage.getItem('addressLng');
    this.storeReq
      .getStoresByStoreGroup(this.groupId, this.lat, this.lng, this.postalCode)
      .subscribe((data) => {
        console.log('---------------', data);
        this.showBanner = true;
        this.stores = data;
      });
    this.storeGroupId = this.groupId;

    let token = localStorage.getItem('token');

    let user = localStorage.getItem('userId');
    this.addressId = localStorage.getItem('addressId');

    this.user.getAddressesByUser(user, token).subscribe((data) => {
      this.addresses = data;
      this.getAddress(this.addressId);
    });

    return this.storeGroupId;
  }

  getAddress(id: string) {
    for (let i = 0; i < this.addresses.length; i++) {
      if (this.addresses[i].id === id) {
        let addressFull = Array.prototype.concat.call(
          this.addresses[i].address +
            ' ' +
            this.addresses[i].number +
            ' ' +
            this.addresses[i].city
        );

        this.currentAddress = addressFull;

        return this.currentAddress;
      }
    }
  }

  getKeys(obj: any): Array<string> {
    return Object.keys(obj);
  }

  getServiceCount(counter: number) {
    this.count = counter;
  }

  leave() {
    this.router.navigate(['/address']);
  }
}
