<div>
  <div id="buttons" class="flex-column flex-lg-row">
    <a
      href="https://apps.apple.com/us/app/e-cleaning/id1570823435"
      class="btn btn-store"
    >
      <span class="fa fa-apple fa-3x pull-left"></span>
      <span class="btn-label">Κατέβασε το App στο</span>
      <span class="btn-caption">App Store</span>
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=gr.softweb.ecleaning"
      class="btn btn-store"
    >
      <span class="fa fa-android fa-3x pull-left"></span>
      <span class="btn-label">Κατέβασε το App στο</span>
      <span class="btn-caption">Google Play</span>
    </a>
  </div>
</div>
