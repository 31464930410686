import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IUser } from 'src/app/stores/models/User';
import { AuthService } from 'src/app/_services/auth.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user : IUser[] = []
  loginForm:FormGroup;
  confirmForm:FormGroup;

  phoneCode = '+30';
  phoneFull ='';

  isLoggedIn = false;
  isLoginFailed =false;
  errorMessage = '';
  

  showHide: Boolean = true;
  constructor(private authService: AuthService,private router:Router, private tokenStorage: TokenStorageService,
    private fb:FormBuilder,
    private fb2: FormBuilder
    

    ) {

      if (localStorage.getItem('token')) {
        this.router.navigateByUrl('/categories');
      }

     }

  ngOnInit(): void {

    this.createLoginForm();
    this.createConfirmForm();


/* 
    (this.tokenStorage.getToken()){
      this.isLoggedIn = true;   
    }
 */
}

showHideContent( ){
  this.showHide = this.showHide ? false : true;
  
   
}

createLoginForm(){

this.loginForm= this.fb.group({
  phone: [null, [Validators.required]]
})

}


createConfirmForm(){
  this.confirmForm= this.fb2.group({
    phone: [],
    confirmCode: [null, [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.maxLength(4)
    ]]
  })
}


onSubmitPhone(){
  if(this.loginForm.value.phone != null){
  let string1 = this.loginForm.value.phone;
  this.phoneFull = this.phoneCode.concat(string1);
  this.loginForm.value.phone = this.phoneFull
  console.log(this.phoneFull)
  
  
    this.authService.login(this.loginForm.value).subscribe(
      data => {
        console.log(data);
       
        this.showHideContent();
        return this.phoneFull;
      },
      err => {
        this.errorMessage = err.error.message;
      }/* ,()=>{this.loading =false} */
    
    );
  }
  

  
  
}

onSubmitSms(){
 
  console.log(this.phoneFull)
    
    this.confirmForm.value.phone =this.phoneFull


    this.authService.confirmSms(this.confirmForm.value).subscribe(
      data => {
        console.log(data);
        this.router.navigateByUrl('/address');
        this.isLoggedIn=true;
        this.isLoginFailed=false;
      },
      err => {
        this.errorMessage= err.error.message;
        this.isLoginFailed=true;
      }
    );

  }
  



  /* onSubmit() {
    this.authService.login(this.form).subscribe(
      data => {
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        
        this.reloadPage();
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  } */


  reloadPage() {
    window.location.reload();
  }


 

}
