import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from 'src/app/stores/models/User';
import { CartService } from 'src/app/stores/services/cart.service';
import { PaymentService } from 'src/app/stores/services/payment.service';

declare var everypay;

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
})
export class PaymentsComponent implements OnInit {
  @ViewChild('pay-form', { static: true }) everypayElement: ElementRef;
  showButton = false;
  customer: any;
  orderId: string;
  amount: string;
  userProfile: IUser;

  payload: any = {};

  constructor(
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private router: Router,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.orderId = params.get('orderId');
      this.amount = params.get('amount');

      console.log('this amount', this.amount);

      console.log('this order', this.orderId);
      this.payload = {
        pk: 'pk_5UsMbANC4YTFc2zxWWr5OT7yZOeUF8pR',
        amount: +this.amount * 100,
        locale: 'el',
        txnType: 'tds',
        buttonOptions: {
          background: '#00aeff',
        },
        data: undefined,
      };
      let myJSON = localStorage.getItem('userProfile');
      this.userProfile = JSON.parse(myJSON);
      this.customer = this.userProfile?.customer;
      if (this.customer?.customerToken) {
        this.payload.data = this.customer;
        this.showButton = true;
        everypay.tokenized(this.payload, this.handleResponse);
      } else {
        everypay.payform(this.payload, this.handleResponse);
      }
    });
  }

  handleResponse = (r) => {
    const Authtoken = localStorage.getItem('token');
    console.log('token2', Authtoken);
    if (r.response === 'success') {
      //TO DO na ginei service
      // You have the token! Submit it to your backend
      this.paymentService
        .sendPayment(r, this.payload.amount, this.orderId)
        .subscribe(
          (order) => {
            console.log(order);
            window.alert('Η παραγγελία σας πραγματοποιήθηκε με επιτυχία');
            localStorage.removeItem('localCart');
            localStorage.removeItem('cart_id');
            this.cartService.deleteCart();
            this.router.navigate(['/categories']).then(() => {
              window.location.reload();
            });
          },
          (err) => {}
        );
    } else {
      // Inform the user if there was an error
    }
  };

  moveBack() {
    this.showButton = false;
    const payload2 = {
      pk: 'pk_5UsMbANC4YTFc2zxWWr5OT7yZOeUF8pR',
      amount: +this.amount * 100,
      locale: 'el',
      txnType: 'tds',

      buttonOptions: {
        background: '#00aeff',
      },
    };
    everypay.payform(payload2, this.handleResponse);
  }
}
