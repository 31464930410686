<div class="img">
  <img
    class="d-block w-100 banner"
    src="../../../assets/carousel1.png
    "
    alt="First slide"
  />
</div>

<div class="d-flex w-100 justify-content-center containerA">
  <div class="itemsView">
    <div class="outerText">
      <div class="text1">Ιστορικό</div>
      <div class="text2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </div>
    </div>
    <div
      class="row"
      *ngFor="let order of orders; let o = odd; let e = even"
      [ngClass]="{ odd: o, even: e }"
      (click)="onSelectOrder(order)"
    >
      <div
        class="txt3 d-flex align-items-center w-75"
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <div
          style="display: flex; flex-direction: row"
          
        >
          <div>
            <img class="storeImg" [src]="order.storeInfo.imgUrl" />
          </div>
          <div
            style="margin-left: 1rem; font-weight: bold; padding-top: 0.3rem"
          >
            {{ order.storeInfo.name }}
          </div>
        </div>
        <div>{{ order.dateCreated | date: "dd/MM/yyyy" }}</div>
      </div>
    </div>
  </div>
</div>
