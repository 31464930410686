import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  createOrder(value) {
    const token = localStorage.getItem('token');
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    });
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', `${token}`);
    return this.http.post(this.apiUrl + '/order', value, { headers });
  }
}
