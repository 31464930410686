import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-buttons',
  templateUrl: './download-buttons.component.html',
  styleUrls: ['./download-buttons.component.css']
})
export class DownloadButtonsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
