<section>
  <div class="container">
    <div id="left">
      <div class="container2" fxLayout="column" fxLayoutAlign="center">
        <div fxFlex>
          <agm-map
            [latitude]="lat.value ? +lat.value : mapCenter.lat"
            [longitude]="lng.value ? +lng.value : mapCenter.lng"
            [zoom]="zoom"
            (mapClick)="onChoseLocation($event)"
          >
            <agm-marker
              [latitude]="+lat.value"
              [longitude]="+lng.value"
              *ngIf="locationChosen"
            ></agm-marker>
          </agm-map>
        </div>
        <!-- <div class="row justify-content-between m-2">
          <div class="form-group">
            <label class="label">Latitude</label>
            <input [formControl]="lat" class="col-6" type="text" />
          </div>
    
          <div class="form-group">
            <label class="label">Longitude</label>
            <input [formControl]="lng" class="col-6" type="text" />
          </div>
        </div> -->

        <div class="form-group">
          <input
            type="text"
            class="form-control"
            (keydown.enter)="$event.preventDefault()"
            placeholder="Αναξήτηση Διεύθυνσης"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="off"
            type="text"
            value="{{ this.address }}"
            #search
          />
        </div>
      </div>

      <form
        [formGroup]="addressForm"
        class="form-inline"
        (ngSubmit)="onSubmit()"
      >
        <div class="flexform">
          <div>
            <input
              class="storeView"
              type="floor"
              id="input-floor"
              formControlName="floor"
              placeholder="Όροφος*"
            />
          </div>
          <div>
            <input
              class="storeView"
              type="doorbell"
              id="input-doorbell"
              formControlName="doorbellName"
              placeholder="Κουδούνι*"
            />
          </div>

          <div>
            <textarea
              class="storeView2"
              name="message"
              type="text"
              id="input-message"
              formControlName="comment"
              placeholder="Σχόλιο"
            ></textarea>
          </div>
        </div>
        <div id="btn">
          <input
            class="btn btn-outline-light blue-background2 white"
            type="submit"
            value="Επιβεβαίωση"
            id="input-submit"
            [disabled]="addressForm.invalid"
          />
        </div>
      </form>
      <div id="reglink">
        <div style="font-weight: bold"><</div>
        <a [routerLink]="['/address']"> Πίσω </a>
      </div>
    </div>

    <div id="img">
      <img id="photof" src="../../../assets/address.jpg" />
    </div>
  </div>
</section>
