import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAddress } from 'src/app/stores/models/address';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-address-choice',
  templateUrl: './address-choice.component.html',
  styleUrls: ['./address-choice.component.css'],
})
export class AddressChoiceComponent implements OnInit {
  addresses: IAddress[] = [];
  public dropdownItem: string;
  public addressChoice: string;

  constructor(private user: UserService, private router: Router) {}

  ngOnInit(): void {
    let token = localStorage.getItem('token');
    console.log(token);
    let user = localStorage.getItem('userId');
    console.log(user);

    this.user.getAddressesByUser(user, token).subscribe((data) => {
      this.addresses = data;
      if (this.addresses[0]) {
        this.chooseAddress(this.addresses[0]);
      }
    });
  }

  chooseAddress(item) {
    console.log(item);

    let addressFull = Array.prototype.concat.call(
      item.address + ' ' + item.number + ' ' + item.city + ' ' + item.postalCode
    );
    console.log(addressFull);
    this.dropdownItem = addressFull;
    this.addressChoice = item.id;
    console.log(this.addressChoice);
    localStorage.setItem('addressId', this.addressChoice);
    console.log(localStorage.getItem('addressId'));
    localStorage.setItem('addressLat', item.lat);
    console.log(localStorage.getItem('addressLat'));
    localStorage.setItem('addressLng', item.lng);
    console.log(localStorage.getItem('addressLng'));
    localStorage.setItem('postalCode', item.postalCode);
    console.log(localStorage.getItem('postalCode'));
  }

  confirmAddress() {
    if (this.dropdownItem) {
      this.router.navigate(['/categories']);
    }
  }

  deleteAdress(id) {
    let token = localStorage.getItem('token');
    console.log('i delete', id);
    this.user.deleteAddress(token, id).subscribe((data) => {
      console.log(data);
    });
    window.location.reload();
    // let container = document.getElementById('delete');
    // let content = container.innerHTML;
    // container.innerHTML = content;
  }
}
