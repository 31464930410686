import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IAddress } from '../stores/models/address';
import { environment } from 'src/environments/environment';
import { IUser } from '../stores/models/User';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private addresSource = new BehaviorSubject<IAddress>(null);
  address$ = this.addresSource.asObservable();
  num = '';
  address = '';
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router) {}

  getAddressesByUser(userId: string, token: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    });
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', `${token}`);
    return this.http.get<IAddress[]>(this.apiUrl + '/address?user=' + userId, {
      headers,
    });
  }

  createAddress(token: string, address) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    });
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', `${token}`);
    return this.http.post(this.apiUrl + '/address', address, { headers });
  }

  deleteAddress(token: string, id: string) {
    console.log('idelete2');
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    });

    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', `${token}`);
    return this.http.delete(this.apiUrl + '/address/' + id, { headers });
  }

  getOrdersForUser(token: string, userId: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    });
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', `${token}`);
    return this.http.get(this.apiUrl + '/order?user=' + userId, { headers });
  }

  getUserProfile(token: string, userId: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    });
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', `${token}`);
    return this.http.get<IUser>(this.apiUrl + '/profile?user=' + userId, {
      headers,
    });
  }

  updateUserProfile(
    token: string,
    userId: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string
  ) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    });
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', `${token}`);
    return this.http.patch(
      this.apiUrl + '/profile/' + userId,
      {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
      },
      {
        headers,
      }
    );
  }

  updateUserInvoice(
    token: string,
    userId: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    name: string,
    afm: string,
    doy: string,
    invoicemail: string,
    job: string,
    invoicephone: string,
    address: string
  ) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    });
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', `${token}`);
    return this.http.patch(
      this.apiUrl + '/profile/' + userId,
      {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        invoice: {
          name: name,
          afm: afm,
          doy: doy,
          email: invoicemail,
          job: job,
          phone: invoicephone,
          address: address,
        },
      },
      {
        headers,
      }
    );
  }
}
