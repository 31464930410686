<section>
  <div class="main-container" *ngIf="showHide">
    <div id="left">
      <form
        class="form-inline main-form"
        [formGroup]="loginForm"
        (ngSubmit)="onSubmitPhone()"
      >
        <h1 class="txt">Καλώς ήρθατε!</h1>
        <div class="txt2">
          lorem ipsum dolor sitgit , consectetur adipiscing elit. Pellentesque
          in nulla dictum, iaculis nunc non, sollicitudin metus. Nulla pulvinar
          laoreet odio sit amet lacinia.
        </div>

        <input
          formControlName="phone"
          class="form-control form-control-width phone-input mb-2"
          placeholder="69x xxx xxxx"
          aria-label="Search"
          mask="000-000-0000"
          maxlength="12"
          minlength="10"
        />
        <button
          class="btn btn-outline-light blue-background white mb-2 px-4"
          type="submit"
          [disabled]="loginForm.invalid"
        >
          Είσοδος
        </button>
      </form>
      <div id="reglink">
        <span
          >Δεν έχετε λογαριασμό?
          <a [routerLink]="['/signup']"> Εγγραφή </a></span
        >
      </div>
    </div>

    <div class="right">
      <div id="img">
        <img id="photof" src="../../../assets/app_DL.png" />
      </div>

      <div>
        <app-download-buttons></app-download-buttons>
      </div>
    </div>
  </div>

  <div class="main-container" *ngIf="!showHide">
    <div id="left">
      <form
        class="form-inline main-form"
        [formGroup]="confirmForm"
        (ngSubmit)="onSubmitSms()"
      >
        <div>
          <h1 class="txt4">Εισάγετε τον κωδικό</h1>
          <h1 class="txt4">που λάβατε με SMS</h1>
        </div>

        <input
          class="form-control form-control-width w-75 marginSMS phone-input"
          maxlength="8"
          minlength="4"
          formControlName="confirmCode"
          id="txt3"
          placeholder=" - - - -"
          aria-label="Search"
          mask="0-0-0-0"
        />
        <input
          class="btn btn-outline-light blue-background white marginSMS"
          type="submit"
          value="Είσοδος"
          id="input-submit"
          [disabled]="confirmForm.invalid"
        />
      </form>
      <div id="reglink">
        <a [routerLink]=""> Επαναποστολή κωδικού </a>
      </div>
    </div>

    <div id="img">
      <img id="photof" src="../../../assets//sms code.png" />
    </div>
  </div>
</section>

<!-- {{loginForm.value |json}}
{{confirmForm.value |json}} -->
