<div
  style="
    display: flex;
    justify-content: center;
    width: 94%;
    flex-direction: column;
    align-items: center;
  "
>
  <!-- My App -->
  <button
    *ngIf="showButton"
    id="back"
    type="button"
    style="
      cursor: pointer;
      padding: 0.5rem;
      background-color: #00aeff;
      margin: 1rem auto;
      outline: none;
      border: none;
      float: right;
      border-radius: 4px;
      color: white;
    "
    (click)="moveBack()"
  >
    Πληρωμή με άλλη κάρτα
  </button>
  <div>
    <div
      class="custom-control custom-radio"
      style="margin-left: auto; margin-right: auto"
    >
      <div id="pay-form"></div>
    </div>
  </div>
</div>
