<p>cart works!</p>


<div *ngIf="(cart$ | async) === null">
    <p>There are no items in your basket</p>
</div>

<div *ngIf="cart$ | async">

    <div  *ngFor="let cart of cart;">
        {{(cart$ | async).item.name}}
    </div>
   
    [items]="(cart$ | async).items"
</div>