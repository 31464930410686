import { isNgTemplate } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat } from 'rxjs';
import { ContentCategoryComponent } from 'src/app/wrapper/content-category/content-category.component';
import { Cart, CartItem, ICart, ICartTotals } from '../models/Cart';
import { ISingleStore } from '../models/singleStore';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartSource = new BehaviorSubject<ICart>(null);
  cart$ = this.cartSource.asObservable();
  private cartTotalSource = new BehaviorSubject<ICartTotals>(null);
  cartTotal$ = this.cartTotalSource.asObservable();

  shipping = 0;

  constructor() {}

  public addToCart(prod: ICart) {}

  private createCart(): ICart {
    const cart = new Cart();
    localStorage.setItem('cart_id', cart.id);
    return cart;
  }

  getCurrentCartValue() {
    return this.cartSource.value;
  }

  calculateTotals(total: number, subtotal: number) {
    this.cartTotalSource.next({ total, subtotal });
  }

  addItemtoCart(item: ISingleStore, items2?: any[], quantity = 1) {
    // console.log('1', item, items2);
    let i = 0;
    item.consumables.forEach((element) => {
      if (element.quantity != 0) {
        console.log('2', element);

        const ItemToAdd: CartItem = this.mapProductItemToCartItem(
          item,
          quantity,
          i
        );
        let cart = this.getCurrentCartValue();
        if (cart === null) {
          cart = this.createCart();
        }
        cart.items = this.addOrUpdateItems(cart.items, ItemToAdd, quantity);
        this.cartSource.next(cart);
        console.log(cart);
      } else {
        let cart = this.getCurrentCartValue();
        const ItemToAdd: CartItem = this.mapProductItemToCartItem(
          item,
          quantity,
          i
        );

        if (cart != null && element.type !== 'square-meter') {
          const elementIndex = cart.items.findIndex(
            (it) =>
              it.category === ItemToAdd.category && it.name === ItemToAdd.name
          );

          if (elementIndex !== -1) {
            cart.items = cart.items.filter(
              (it, index) => index !== elementIndex
            );

            this.cartSource.next(cart);
          }
          //  this.removeItemFromCart(ItemToAdd);
        } else if (element.type === 'square-meter') {
          if (!cart) {
            cart = this.createCart();
          }

          const itemsFound = items2.filter(
            (it) =>
              it.categoryName === ItemToAdd.category &&
              it.name === ItemToAdd.name
          );

          console.log('itemsFound', itemsFound);
          if (itemsFound.length) {
            let extras = [];
            itemsFound.map((it) => {
              extras = extras.concat(it.extras);
            });
            const itemForMap = {
              category: ItemToAdd.category,
              name: ItemToAdd.name,
              quantity: itemsFound.reduce((a, b) => a + b.extras?.quantity, 0),
              price: ItemToAdd.price,
              type: ItemToAdd.type,
              extras: extras,
            };

            console.log('itemForMap', itemForMap);

            cart.items = this.addOrUpdateItems(
              cart.items,
              itemForMap,
              quantity
            );
            this.cartSource.next(cart);
          }
        }
      }

      i++;
    });
  }

  private addOrUpdateItems(
    items: CartItem[],
    itemToAdd: CartItem,
    quantity: number
  ): CartItem[] {
    const index = items.findIndex(
      (i) => i.name === itemToAdd.name && i.category == itemToAdd.category
    );
    console.log(index);
    if (index === -1) {
      items.push(itemToAdd);
      let qnt = 0;

      if (itemToAdd.type !== 'square-meter') {
        items[0].extras.forEach((element) => {
          qnt += element.quantity;
        });

        items[0].quantity = qnt;
      }
    } else {
      console.log('Hello!!!');
      if (itemToAdd.type !== 'square-meter') {
        items[index].extras.forEach((element, extraindex) => {
          element.quantity = itemToAdd.extras[extraindex].quantity;
        });
        items[index].quantity = itemToAdd.quantity;
      } else {
        console.log('Hello 2', items, itemToAdd);

        items[index] = { ...itemToAdd };
      }
    }
    return items;
  }

  removeItemFromCart(item: CartItem) {
    const cart = this.getCurrentCartValue();
    if (
      cart.items.some(
        (x) => x.name === item.name && x.category === item.category
      )
    ) {
      cart.items = cart.items.filter(
        (i) => i.name != item.name && i.category != item.category
      );
      if (cart.items.length > 0) {
        this.cartSource.next(cart);
      } else {
        this.deleteCart();
      }
    }
  }

  deleteCart() {
    this.cartSource.next(null);
    this.cartTotalSource.next(null);
    localStorage.removeItem('cart_id');
  }
  private mapSqFtItemToCartItem(item2: any): CartItem {
    return {
      price: item2.price,
      category: item2.categoryName,
      quantity: item2.quantity,
      name: item2.name,
      extras: item2.extras,
      type: item2.type,
    };
  }

  private mapProductItemToCartItem(
    item: ISingleStore,
    quantity: number,
    key: number
  ): CartItem {
    return {
      price: item.consumables[key].price,
      category: item.name,
      quantity: item.consumables[key].quantity,
      name: item.consumables[key].name,
      extras: item.consumables[key].extras?.filter((ex) => ex.quantity > 0),
      type: item.consumables[key].type,
    };
  }

  initializeCart() {
    let newCart: any = localStorage.getItem('localCart');
    if (!newCart) {
      return;
    }
    newCart = JSON.parse(newCart);
    this.cartSource.next(newCart);
  }

  setupCartFromHistory(items: CartItem[], totalPrice: string, storeId: string) {
    let cart = this.getCurrentCartValue();
    if (cart === null) {
      cart = this.createCart();
    }
    cart.items = items;
    cart.totalPrice = +totalPrice;
    cart.storeId = storeId;

    this.cartSource.next(cart);
  }
}
