<section>
  <div class="container">
    <div id="left">
      <form
        [formGroup]="registerForm"
        class="form-inline"
        (ngSubmit)="onSubmit()"
      >
        <h1 class="txt">Γίνετε μέλος</h1>

        <div class="flexform">
          <div>
            <input
              class="storeView"
              id="txt2"
              name="name"
              placeholder="Όνομα*"
              formControlName="firstName"
            />
          </div>
          <div>
            <input
              class="storeView"
              name="lastname"
              placeholder="Επώνυμο*"
              formControlName="lastName"
            />
          </div>
          <div>
            <input
              class="storeView"
              name="mobile"
              placeholder="Κινητό*"
              formControlName="phone"
              mask="000 000 0000"
            />
          </div>
          <div>
            <input
              class="storeView"
              name="mail"
              placeholder="e-mail*"
              formControlName="email"
            />
          </div>
        </div>
        <div id="btn">
          <button
            class="btn btn-outline-light blue-background white txt"
            type="submit"
            [disabled]="registerForm.invalid"
          >
            Εγγραφή
          </button>
        </div>
      </form>

      <!-- {{registerForm.value |json}} -->
    </div>

    <div id="img">
      <img id="photof" src="../../../assets/signup.jpg" />
    </div>
  </div>
</section>
