import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) {}
  apiUrl = environment.apiUrl;

  sendPayment(r, amount, order) {
    const Authtoken = localStorage.getItem('token');
    console.log('token', Authtoken);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    });
    headers = headers
      .set('Content-Type', 'application/json')
      .set('Authorization', `${Authtoken}`);
    return this.http.post(
      this.apiUrl + '/payment/checkout',
      {
        token: r.token,

        orderID: order,
        amount,
        uuid: r.uuid,
      },
      { headers }
    );
  }
}
