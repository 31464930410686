import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IsLogged } from 'src/app/stores/models/IsLogged';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  showHide: Boolean = true;
  isLogged: Boolean = false;

  constructor(private router: Router, private user: AuthService) {}

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.user.isLoggedSource.next(true);
    } else {
      this.user.isLoggedSource.next(false);
    }

    /* 
      console.log(this.user.isLogged$)
      console.log(localStorage.getItem('token'))
     */
  }

  showHideContent() {
    this.showHide = this.showHide ? false : true;
  }

  goHome() {
    if (localStorage.getItem('token')) {
      this.router.navigate(['/categories']);
    } else {
      this.router.navigate(['']);
    }
    this.showHideContent();
  }
  goHomeNotHidden(logout?) {
    if (logout) {
      this.user.logout();
    }
    if (localStorage.getItem('token')) {
      this.router.navigate(['/categories']);
    } else {
      this.router.navigate(['']);
    }
  }

  goAddress() {
    this.router.navigate(['/address']);
    this.showHideContent();
  }

  goOrders() {
    this.router.navigate(['/orders']);
    this.showHideContent();
  }
  goPrivacy() {
    this.router.navigate(['/settings', 'politiki-aporr']);
    this.showHideContent();
  }
  goProfile() {
    this.router.navigate(['/profile']);
    this.showHideContent();
  }
}
