import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IStoreGroups } from 'src/app/stores/models/storeGroups';
import { RequestsService } from 'src/app/stores/services/requests.service';

@Component({
  selector: 'app-content-category',
  templateUrl: './content-category.component.html',
  styleUrls: ['./content-category.component.css'],
})
export class ContentCategoryComponent implements OnInit {
  storeGroups: IStoreGroups[] = [];

  constructor(private groupReq: RequestsService, private router: Router) {}

  ngOnInit() {
    this.groupReq
      .getStoreGroups()
      .subscribe((data) => (this.storeGroups = data));
  }

  getKeys(obj: any): Array<string> {
    return Object.keys(obj);
  }

  showStores(groupData) {
    this.router.navigate(['', groupData]);
  }
}
