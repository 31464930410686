import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IStore } from 'src/app/stores/models/store';
import { RequestsService } from 'src/app/stores/services/requests.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
})
export class CarouselComponent implements OnInit {
  storeId: string;
  store: IStore;

  constructor(
    private route: ActivatedRoute,
    private storeReq: RequestsService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.storeId = params.get('store');
      this.storeReq.getStoreById().subscribe((res) => {
        this.store = res.find((store) => (store.id = this.storeId));
      });
    });
    /*  console.log(this.storeId); */
  }
}
