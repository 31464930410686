import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './_services/auth.service';

@Injectable()
export class LoginAuthGuard implements CanActivate {
  
  constructor(private _authService: AuthService, private _router: Router,) { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    console.log('this works', this._authService.loggedIn())
      if (this._authService.loggedIn()) {
        console.log('works')
          this._router.navigate(['/categories'])
          return false
      } else {
          return true
      }
  }
  
}
