import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from '../stores/models/User';
import { BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IsLogged } from '../stores/models/IsLogged';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl = environment.apiUrl;
  private currentUserSource = new BehaviorSubject<IUser>(null);
  currentUser$ = this.currentUserSource.asObservable();

  public isLoggedSource = new BehaviorSubject(false);
  isLogged$ = this.isLoggedSource.asObservable();

  constructor(private http: HttpClient, private router: Router) {}

  login(values): Observable<any> {
    return this.http.post(this.apiUrl + '/login/', values, {
      headers: new HttpHeaders().set(
        'Authorization',
        'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
      ),
    });
  }

  onDataReceived(boolean) {
    this.isLoggedSource.next(boolean);
    console.log(this.isLogged$);
  }

  confirmSms(values): Observable<any> {
    return this.http
      .post(this.apiUrl + '/sms/', values, {
        headers: new HttpHeaders().set(
          'Authorization',
          'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
        ),
      })
      .pipe(
        map((user: IUser) => {
          if (user) {
            localStorage.setItem('token', user.token);
            localStorage.setItem('userId', user.id);
            let myJSON = JSON.stringify(user);
            localStorage.setItem('userProfile', myJSON);
            this.currentUserSource.next(user);

            console.log(user);
            console.log(user.token);
            this.onDataReceived(true);
          }
        })
      );
  }

  getUserData(): Observable<any> {
    return this.currentUserSource.asObservable();
  }

  logout() {
    localStorage.clear();
    this.currentUserSource.next(null);
    this.onDataReceived(false);
    this.router.navigateByUrl('/login');
  }
  loggedIn() {
    return localStorage.getItem('token');
  }

  register(values): Observable<any> {
    return this.http
      .post(this.apiUrl + '/signup/', values, {
        headers: new HttpHeaders().set(
          'Authorization',
          'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
        ),
      })
      .pipe(
        map((user: IUser) => {
          if (user) {
            localStorage.setItem('token', user.token);
            localStorage.setItem('token', user.token);
            localStorage.setItem('userId', user.id);
            let myJSON = JSON.stringify(user);
            localStorage.setItem('userProfile', myJSON);
            this.currentUserSource.next(user);
          }
        })
      );
  }

  isAuthenticated() {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }
}
