<section>
  <div
    id="carouselExampleSlidesOnly"
    class="carousel-slide"
    data-ride="carousel"
  >
    <div class="carousel-inner zAxis">
      <div class="carousel-item active">
        <img
          class="d-block w-100"
          src="../../../assets/carousel1.png
          "
          alt="First slide"
        />
      </div>
    </div>
  </div>
</section>
