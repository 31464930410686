import { Component } from '@angular/core';
import { CartService } from './stores/services/cart.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(private cartService: CartService) {
    this.cartService.initializeCart();
  }
  title = 'Eclean';
}
