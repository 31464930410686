import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISetting } from '../models/setting';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getSettingsByName(option: string) {
    return this.http.get<ISetting>(this.apiUrl + '/settings/' + option, {
      headers: new HttpHeaders().set(
        'Authorization',
        'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
      ),
    });
  }
}
