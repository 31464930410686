import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { NavComponent } from './nav/nav.component';
import { ContentComponent } from './content/content.component';
import { FooterComponent } from './footer/footer.component';
import { WrapperComponent } from './wrapper/wrapper.component';
import { CarouselComponent } from './carousel/carousel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentCategoryComponent } from './content-category/content-category.component';
import { RouterModule, Routes } from '@angular/router';
import { ContentStoreComponent } from './content-store/content-store.component';
import { AddressPageComponent } from './address-page/address-page.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { CartComponent } from './cart/cart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BannerComponent } from './banner/banner.component';
import { OnCreateDirective } from './content-store/on-create.directive';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { AddressChoiceComponent } from './address-choice/address-choice.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbInputModule, NbThemeModule } from '@nebular/theme';
import { CheckoutComponent } from './checkout/checkout.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { DownloadButtonsComponent } from './download-buttons/download-buttons.component';
import { SearchPipe } from '../pipes/search.pipe';
import { HistoryComponent } from './history/history.component';
import { SettingsComponent } from './settings/settings.component';
import { PaymentsComponent } from './payments/payments.component';
import { BannerEmptyComponent } from './banner-empty/banner-empty.component';
import { ProfileComponent } from './profile/profile.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'categories', component: ContentCategoryComponent },
];

@NgModule({
  declarations: [
    HeaderComponent,
    NavComponent,
    CarouselComponent,
    ContentComponent,
    FooterComponent,
    WrapperComponent,
    CarouselComponent,
    BannerComponent,
    ContentCategoryComponent,
    ContentStoreComponent,
    AddressPageComponent,
    LoginComponent,
    SignupComponent,
    CartComponent,
    BannerComponent,
    OnCreateDirective,
    AddressChoiceComponent,
    CheckoutComponent,
    DownloadButtonsComponent,
    SearchPipe,
    HistoryComponent,
    SettingsComponent,
    PaymentsComponent,
    BannerEmptyComponent,
    ProfileComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAP_KEY,
      libraries: ['places'],
      language: 'el',
    }),
    MatGoogleMapsAutocompleteModule,
    BrowserAnimationsModule,
    DpDatePickerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    WrapperComponent,
    HeaderComponent,
    FooterComponent,
    ContentCategoryComponent,
    ContentStoreComponent,
    LoginComponent,
    SettingsComponent,
    OnCreateDirective,
    BrowserAnimationsModule,
    DpDatePickerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
  ],
  providers: [],
  bootstrap: [WrapperComponent, ContentComponent, CarouselComponent],
})
export class WrapperModule {}
