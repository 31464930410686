import { MapsAPILoader, LazyMapsAPILoader } from '@agm/core';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  NgZone,
  ViewEncapsulation,
} from '@angular/core';
import {
  Location,
  Appearance,
  GermanAddress,
} from '@angular-material-extensions/google-maps-autocomplete';
import { Title } from '@angular/platform-browser';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { UserService } from 'src/app/_services/user.service';
import { IAddress } from '../../stores/models/address';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import { Router } from '@angular/router';

@Component({
  selector: 'app-address-page',
  templateUrl: './address-page.component.html',
  styleUrls: ['./address-page.component.css'],
})
export class AddressPageComponent implements OnInit {
  /* 
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
 */

  mapCenter = {
    lat: 40.63232868179058,
    lng: 22.94083684050897,
  };
  lat = new FormControl();
  lng = new FormControl();

  zoom = 18;
  locationChosen = false;

  address: string;
  private geoCoder;

  showHide: Boolean = true;
  var: string;

  addressVerb = {
    /*  number: '',
      address: '',
     
      area: '',
      city:'',
      county:'',
      postalCode:'', */
  };
  addressForm: FormGroup;

  errorMessage = '';

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;

  constructor(
    private user: UserService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    /* 
    this.zoom = 10;
    this.latitude = 52.520008;
    this.longitude = 13.404954;

    this.setCurrentPosition();
 */

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      console.log('!st Time', this.lat.value, this.lng.value);

      if (this.lat.value && this.lng.value) {
        this.locationChosen = true;
      }

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.lat.setValue(place.geometry.location.lat());
          this.lng.setValue(place.geometry.location.lng());

          this.locationChosen = true;
          this.onChooseLocationFromBar(this.lat.value, this.lng.value);
        });
      });
    });

    let token = localStorage.getItem('token');
    console.log(token);
    let user = localStorage.getItem('userId');
    console.log(user);

    this.user.getAddressesByUser(user, token).subscribe((data) => {
      console.log(data);
    });

    this.createAddressForm();
  }

  createAddressForm() {
    this.addressForm = this.fb.group({
      floor: [null, [Validators.required]],
      doorbellName: [null, [Validators.required]],
      comment: [null],
    });
  }

  onSubmit(): IAddress {
    const address: IAddress = this.mapAddresstoIAddress(
      this.lat.value,
      this.lng.value,
      this.addressVerb,
      this.addressForm.value
    );
    if (!address) {
      return;
    }
    let token = localStorage.getItem('token');
    this.user.createAddress(token, address).subscribe(
      (data) => {
        console.log(data);
        this.router.navigateByUrl('/address');
      },
      (err) => {
        this.errorMessage = err.error.message;
      }
    );
    console.log(
      this.lat.value,
      this.lng.value,
      this.addressVerb,
      this.addressForm.value
    );
    return address;
  }
  mapAddresstoIAddress(lat, lng, addressGgl, addressForm): IAddress {
    if (!addressGgl[5]) {
      window.alert(
        'Η διεύθυνση που επιλέξατε δεν είναι έγκυρη. Παρακαλώ επιλέξτε διεύθυνση από το χάρτη ή από τις επιλογές της αυτόματης συμπλήρωσης'
      );
      return;
    }

    addressGgl[5] = addressGgl[5].replace(' ', '');
    return {
      id: '',
      address: addressGgl[1],
      lat: lat,
      lng: lng,
      number: addressGgl[0],
      area: addressGgl[2],
      city: addressGgl[3],
      floor: this.addressForm.value.floor,
      postalCode: addressGgl[5],
      doorBellName: this.addressForm.value.doorbellName,
      comments: this.addressForm.value.comment,
    };
  }

  onChooseLocationFromBar(lat, lng) {
    let lati = parseFloat(lat);
    let long = parseFloat(lng);

    this.getAddress(lati, long);
  }

  onChoseLocation(event) {
    this.lat.setValue(event.coords.lat);
    this.lng.setValue(event.coords.lng);
    this.locationChosen = true;
    this.getAddress(this.lat.value, this.lng.value);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        // console.log(results);
        // console.log(status);
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 18;
            this.address = results[0].formatted_address;
            console.log(results[0]);
            console.log(this.address);
            this.handleAddress(results[0]);
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  handleAddress(components) {
    let foo = components.address_components;
    console.log(foo);

    for (let i = 0; i < foo.length; i++) {
      this.addressVerb[i] = foo[i].long_name;
    }

    console.log(this.addressVerb);
    console.log(this.lat.value);

    /*  foo.forEach(element => {
    if(element.types[0] === "street_number" ){
      this.addressVerb.number = element.long_name;
    }else
    if(element.types[0] === "route" || element.types[0] === "street_address" ){
      this.addressVerb.address = element.long_name;
    }else if(element.types[0] === "postal_code" ){
      this.addressVerb.postalCode = element.long_name;
    }else
    if(element.types[0] === "locality" ||
    element.types[0] === "sublocality" ||
    element.types[0] === "sublocality_level_1"||
    element.types[0] === "sublocality_level_2"||
    element.types[0] === "sublocality_level_3"||
    element.types[0] === "sublocality_level_4"){
      this.addressVerb.area = element.long_name;
    }else
    if(element.types[0] === "administrative_area_level_1" || 
    element.types[0] === "administrative_area_level_2" ||
    element.types[0] === "administrative_area_level_3"||
    element.types[0] === "administrative_area_level_4"||
    element.types[0] === "administrative_area_level_5" ){
      this.addressVerb.city = element.long_name;
    }
  }); */
  }

  showHideContent(nameCategory: string, nameConsumable: string) {
    this.showHide = this.showHide ? false : true;
  }
}
