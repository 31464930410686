import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ISetting } from 'src/app/stores/models/setting';
import { SettingsService } from 'src/app/stores/services/settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  setting: ISetting;
  option: string;
  private sub: any;

  constructor(
    private settingsService: SettingsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.option = params['option'];
      this.settingsService
        .getSettingsByName(this.option)
        .subscribe((res) => (this.setting = res));
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
