<section class="content-container">
  <div id="container">
    <div id="left">
      <div>
        <h1 class="text">Επιλέξτε Υπηρεσία</h1>
        <p class="txt">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
          egestas rhoncus efficitur. t metus, efficitur se
        </p>
      </div>

      <div
        class="storeView"
        id="text2"
        *ngFor="let storeGroup of storeGroups"
        [routerLink]="['/store']"
        [queryParams]="{
          storegroup: storeGroup.id,
          grouptype: storeGroup.type
        }"
      >
        <!--  (click)="showStores(storeGroup.id)" -->

        <div class="groupImg-ctn">
          <img id="groupImg" [src]="storeGroup.imgUrl" />
        </div>

        <div class="group-name">{{ storeGroup.name }}</div>
      </div>

      <div id="link" class="pb-4">
        <span style="font-weight: bold"><</span>
        <a [routerLink]="'/address'"> Πίσω </a>
      </div>
    </div>

    <div class="img d-lg-block d-none main-img">
      <img class="photo" src="../../../assets/categories.jpg" />
    </div>
  </div>
</section>

<!--  <a [routerLink]="['']">STORES</a> -->
