<!-- <app-carousel *ngIf="store?.hasSpecialOffer"></app-carousel> -->
<!-- <app-banner-empty *ngIf="!store?.hasSpecialOffer"></app-banner-empty> -->

<div id="store" *ngIf="store">
  <div class="storeView">
    <div id="left">
      <img
        id="storeImg"
        class="d-block"
        [src]="store.imgUrl"
        alt="Store Image"
      />
    </div>

    <div id="right">
      <div>
        <span id="text2"> {{ store.name }}</span>
      </div>

      <div class="mt-2">
        <div id="rateStar">
          <div>
            <img
              id="flexImg"
              src="../../../assets/svgWeb/Icon ionic-ios-star.svg"
              alt="Stars"
            />
          </div>
          <div class="blueRating">({{ store.ratingsCount }})</div>
          <div class="greyRating">({{ store.averageQualityRating }})</div>
        </div>
      </div>
      <div class="mt-2" id="text3">{{ store.address }}</div>
      <div class="mt-2" id="text">{{ store.storeDescription }}</div>

      <!-- this is a change -->
    </div>
  </div>
</div>

<div class="Acontainer">
  <div class="reviewView">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a
          class="nav-item nav-link w-100"
          id="nav-review-tab"
          data-toggle="tab"
          href="#nav-review"
          role="tab"
          aria-controls="nav-review"
          aria-selected="true"
          >Κριτικές</a
        >
        <a
          class="nav-item nav-link active w-100"
          id="nav-profile-tab"
          data-toggle="tab"
          href="#nav-info"
          role="tab"
          aria-controls="nav-info"
          aria-selected="false"
          >Πληροφορίες</a
        >
      </div>
    </nav>
    <div class="tab-content reviewTabContent" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="nav-info"
        role="tabpanel"
        aria-labelledby="nav-info-tab"
      >
        <div id="store" *ngIf="store">
          <div>
            <div class="spacer"></div>
            <div id="text6">Τροποι πληρωμής</div>

            <div id="reviewView2">
              <div id="text7" *ngFor="let paymentType of store?.paymentTypes">
                <div
                  class="d-flex justify-content-between align-item-center w-100"
                >
                  {{ paymentType | i18nSelect: inviteMap }}

                  <img [src]="'assets/svg/' + paymentType + '.svg'" />
                </div>
              </div>
            </div>
            <div class="spacer"></div>
            <!-- <div>Reviews</div> -->
            <div id="text6">Διεύθυνση καταστήματος</div>
            <div id="text8">{{ store?.address }}</div>
            <div class="spacer"></div>
            <div class="map" *ngIf="store">
              <agm-map
                [mapDraggable]="false"
                [disableDefaultUI]="true"
                [zoom]="16"
                [latitude]="this.store.lat"
                [longitude]="this.store.lng"
              >
                <agm-marker
                  [latitude]="this.store.lat"
                  [longitude]="this.store.lng"
                ></agm-marker>
              </agm-map>
            </div>
            <div class="spacer"></div>
            <div id="text6">Ωράριο Λειτουργίας</div>
            <div id="text8" *ngFor="let workHour of store.workHours">
              {{ workHour.title }}
              {{ workHour.content }}
            </div>
            <div class="spacer"></div>
          </div>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="nav-review"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
      >
        <div id="reviews">
          <div *ngIf="storeReviews.length === 0">
            <p id="comment">Δεν υπάρχουν Κριτικές</p>
          </div>
          <div *ngFor="let storeReview of storeReviews">
            <div id="singleReview">
              <div id="dateCreated">
                {{ storeReview.dateCreated | date: "dd/MM/yyyy" }}
              </div>

              <div *ngIf="storeReview.comments != ' '">
                <div id="comment">{{ storeReview.comments }}</div>
              </div>
              <div id="ratings">
                <div>Ποιότητα: {{ storeReview.qualityRating }}</div>
                <div>Εξυπηρέτηση: {{ storeReview.serviceRating }}</div>
                <div>Ταχύτητα: {{ storeReview.speedRating }}</div>
              </div>

              <div id="DateofOrder">
                Ημερομηνια Παραγγελίας: {{ storeReview.orderDate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="showHide">
    <div class="itemsView">
      <div class="d-flex flex-row justify-content-between w-100">
        <div id="scroller">
          <div style="float: left">
            <button class="arrow" (click)="scrollLeft()"><</button>
          </div>
          <ul
            class="nav nav-pills nav-justified flex-collumn"
            #vpillstab
            role="tablist"
          >
            <li
              class="nav-item"
              *ngFor="
                let singleStore of singleStore;
                let index = index;
                let isFirst = first
              "
              [ngClass]="{ active: isFirst }"
            >
              <a
                class="nav-link extraWidth"
                id="v-pills-home-tab"
                (click)="selectPill(index)"
                data-toggle="pill"
                href="#v-pills-{{ index }}"
                role="tab"
                aria-controls="v-pills-home"
                >{{ singleStore.name }}</a
              >
            </li>
            <div class="spacer"></div>
          </ul>
          <div style="float: right">
            <button class="arrow" (click)="scrollRight()">></button>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <!-- <button
            class="btn btn-outline-light blue-background white mr-3"
            id="stores"
            (click)="goBack()"
          >
            Καταστήματα
          </button> -->
        </div>
      </div>
      <div
        class="tab-content"
        id="v-pills-tabContent"
        *ngFor="let singleStore of singleStore; let index = index"
      >
        <div
          id="v-pills-{{ index }}"
          role="tabpanel"
          aria-labelledby="v-pills-home-tab "
          class="tab-pane h-100 fade show row"
          [ngClass]="{ active: index === activePillIndex }"
          *ngIf="index === activePillIndex"
        >
          <table>
            <div
              *ngFor="
                let key of getKeys(singleStore.consumables);
                let o = odd;
                let e = even
              "
              [ngClass]="{ odd: o, even: e }"
            >
              <div
                id="row"
                (click)="
                  showHideContent(
                    singleStore.name,
                    singleStore.consumables[key].name,
                    undefined,
                    singleStore.consumables[key].type,
                    content
                  )
                "
              >
                <div id="text4">{{ singleStore.consumables[key].name }}</div>
                <div id="text5">
                  από {{ singleStore.consumables[key].price }}€
                </div>
              </div>
            </div>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="!showHide">
    <div class="itemsView">
      <div *ngFor="let singleStore of singleStore; let storeIndex = index">
        <div *ngIf="singleStore.name === serviceName">
          <div *ngFor="let key of getKeys(singleStore.consumables)">
            <div *ngIf="singleStore.consumables[key].name === itemName">
              <div class="d-flex justify-content-between w-100">
                <div>
                  <div class="text7">
                    {{ singleStore.consumables[key].name }}
                  </div>
                  <div
                    class="text8"
                    *ngIf="singleStore.consumables[key].type != 'square-meter'"
                  >
                    Επιλέξτε την ποσότητα του κάθε είδους που χρειάζεστε
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <button
                    *ngIf="singleStore.consumables[key].type === 'square-meter'"
                    class="btn btn-outline-light blue-background white"
                    id="add"
                    (click)="open(content, this.serviceName)"
                  >
                    Προσθήκη προϊόντος
                  </button>
                  <button
                    class="btn btn-outline-light blue-background white"
                    id="backtostore"
                    (click)="showHideContent(undefined, undefined, storeIndex)"
                  >
                    Πίσω
                  </button>
                </div>
              </div>
              <div *ngIf="singleStore.consumables[key].type != 'square-meter'">
                <div
                  id="row2"
                  *ngFor="
                    let key2 of getKeys(singleStore.consumables[key].extras);
                    let index = index;
                    let o = odd;
                    let e = even
                  "
                  [ngClass]="{ odd: o, even: e }"
                >
                  <div class="rowFlex">
                    <div class="txtFlex">
                      <div id="text4">
                        {{ singleStore.consumables[key].extras[key2].name }}
                      </div>
                      <div>
                        {{ singleStore.consumables[key].extras[key2].price }}€
                        /{{
                          singleStore.consumables[key].type
                            | i18nSelect: inviteMap2
                        }}
                      </div>
                    </div>

                    <div class="text6"></div>
                    <!-- <button (click)="open(content)">Χαλιά..</button> -->

                    <div
                      *ngIf="
                        singleStore.consumables[key].type != 'square-meter'
                      "
                      class="miniFlex"
                    >
                      <div id="price">
                        {{
                          (
                            singleStore.consumables[key].extras[key2].price *
                            singleStore.consumables[key].extras[key2].quantity
                          ).toFixed(2)
                        }}€
                      </div>
                      <div
                        id="remove"
                        (click)="removetotal(serviceName, key, key2)"
                      >
                        <img
                          src="../../../assets/svgWeb/ic_remove_circle_24px.svg"
                          alt="-"
                        />
                      </div>
                      <div class="quantity">
                        {{ singleStore.consumables[key].extras[key2].quantity }}
                      </div>
                      <div id="add" (click)="addtotal(serviceName, key, key2)">
                        <img
                          src="../../../assets/svgWeb/ic_add_circle_24px.svg"
                          alt="+"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="singleStore.consumables[key].type === 'square-meter'">
                <div>
                  <div
                    id="row2"
                    *ngFor="
                      let singleStoreSquar of singleStoreSquare;
                      let index = index;
                      let o = odd;
                      let e = even
                    "
                    [ngClass]="{ odd: o, even: e }"
                  >
                    <div class="rowFlex">
                      <div class="txtFlex">
                        <div id="text4">
                          {{ singleStoreSquar.extras.name }} x{{
                            singleStoreSquar.extras.quantity
                          }}
                        </div>
                        <div>
                          {{ singleStoreSquar.extras.price }}€ /{{
                            singleStoreSquar.type | i18nSelect: inviteMap2
                          }}
                        </div>
                      </div>

                      <div class="text6"></div>
                      <div class="miniFlex">
                        <div id="pricesq">
                          {{
                            (
                              singleStoreSquar.extras.price *
                              singleStoreSquar.extras.width *
                              singleStoreSquar.extras.length
                            ).toFixed(2)
                          }}€
                        </div>

                        <div class="d-flex flex-row">
                          <div class="sq">
                            Μήκος: {{ singleStoreSquar.extras.length }}μ.
                          </div>
                          <div class="sq">
                            Πλάτος: {{ singleStoreSquar.extras.width }}μ.
                          </div>
                        </div>
                        <div id="remove" (click)="removeSq(index, key)">
                          <img
                            src="../../../assets/svgWeb/ic_remove_circle_24px.svg"
                            alt="-"
                          />
                        </div>
                      </div>
                      <!-- <button (click)="open(content)">Χαλιά..</button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="btnFlex">
        <!--    <button class="btn btn-outline-light blue-background2 white" id="add" type="submit">Συνολο</button> -->
        <button
          class="btn btn-outline-light blue-background white"
          id="add"
          (click)="cartView(serviceName)"
        >
          Προσθήκη στο Καλάθι
        </button>
      </div>
    </div>
  </div>

  <div class="cartView">
    <div>
      <div id="text5">Το καλάθι μου</div>

      <div id="store" *ngFor="let store of stores">
        <div *ngIf="store.id === storeId">
          <div *ngIf="!(cart$ | async)" id="text13">
            Το <br />καλάθι σας <br />είναι άδειο
          </div>
        </div>
      </div>
    </div>

    <div class="block">
      <div *ngFor="let cart of (cart$ | async)?.items" c>
        <div class="cartTxtFlex">
          <div class="cartItem">
            <div class="text10">{{ cart.name }}</div>
            <div class="text11">{{ cart.category }}</div>
          </div>

          <div class="text12">x{{ cart.quantity }}</div>
        </div>
      </div>
    </div>

    <div class="cartbtn">
      <div id="text5">
        Σύνολο {{ (cart$ | async)?.totalPrice?.toFixed(2) || 0 }} €
      </div>
      <div class="spacer"></div>
      <button
        class="btn btn-outline-light blue-background white w-100"
        (click)="checkout()"
        [disabled]="!(cart$ | async)"
      >
        Το καλάθι μου
      </button>

      <div class="spacer"></div>
    </div>
  </div>
</div>

<ng-template #content id="content" let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Εισάγετε τις διαστάσεις</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="my-2 mb-4 text-center">
      Μην ξεχάσετε να μετρήσετε και τα κρόσια αν υπάρχουν!
    </div>
    <div *ngFor="let singleStore of singleStore; let storeIndex = index">
      <div *ngIf="singleStore.name === serviceName">
        <div *ngFor="let key of getKeys(singleStore.consumables)">
          <div *ngIf="singleStore.consumables[key].name === itemName">
            <div id="row2">
              <div class="rowFlex justify-content-center">
                <!-- <div class="text6"></div> -->
                <!-- <button (click)="open(content)">Χαλιά..</button> -->

                <div class="miniFlex">
                  <div class="d-flex flex-row">
                    <form #f="ngForm" novalidate>
                      <div clas="form-group">
                        <label for="option" class="d-block"
                          >Επιλέξτε προϊόν</label
                        >
                        <div class="input-group">
                          <select
                            id="option"
                            name="option"
                            class="form-control"
                            ngModel
                            required
                          >
                            <option
                              *ngFor="
                                let option of singleStore.consumables[key]
                                  .extras;
                                let index = index
                              "
                              [value]="index"
                            >
                              {{ option.name }} - {{ option.price }} € / τ.μ.
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between mt-4">
                        <div class="form-group mr-4">
                          <label class="label d-block" for="length"
                            >Μήκος:</label
                          >

                          <input
                            type="number"
                            min="0"
                            id="length"
                            name="length"
                            class="form-control"
                            ngModel
                            required
                          />
                        </div>
                        <div class="form-group">
                          <label class="label d-block" for="width"
                            >Πλάτος:</label
                          >

                          <input
                            type="number"
                            min="0"
                            id="width"
                            name="width"
                            class="form-control"
                            ngModel
                            required
                          />
                        </div>
                      </div>

                      <div class="text-center">
                        <button
                          data-dismiss="modal"
                          class="btn btn-info"
                          type="button"
                          (click)="onSubmit(f, serviceName, key)"
                          [disabled]="f.invalid"
                        >
                          ΟΚ
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Ακύρωση
    </button>
  </div>
</ng-template>

<!-- <ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Εισάγετε τις διαστάσεις</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="my-2">Μην ξεχάσετε να μετρήσετε και τα κρόσια αν υπάρχουν!</div>
    <form #f="ngForm" novalidate>
      <div class="form-group my-2">
        <label for="length">Επιλέξτε προϊόν:</label>
        <div class="input-group">
          <select id="option" name="option" class="form-control" ngModel>
            
            <option
              *ngFor="let option of singleStore[key].consumables[key2].extras"
              [value]="option.name"
            >
              {{ option.name }} - {{ option.price }} € / τ.μ.
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label for="length">Μήκος:</label>
        <div class="input-group">
          <input
            type="number"
            id="length"
            name="length"
            class="form-control"
            ngModel
          />
        </div>
        <div class="form-group">
          <label for="width">Πλάτος:</label>
          <div class="input-group">
            <input
              type="number"
              id="width"
              name="width"
              class="form-control"
              ngModel
            />
          </div>
        </div>
      </div>
      <div class="text-center">
        <button
          data-dismiss="modal"
          class="btn btn-info"
          type="button"
          (click)="onSubmit(f, serviceName, key, key2)"
        >
          ΟΚ
        </button>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Ακύρωση
    </button>
  </div>
</ng-template> -->
