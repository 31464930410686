import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAddress } from 'src/app/stores/models/address';
import { IStore } from 'src/app/stores/models/store';
import { RequestsService } from 'src/app/stores/services/requests.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css'],
})
export class ContentComponent implements OnInit {
  // stores: IStore[] = [];
  stores: IStore[] = [];
  addresses: IAddress[] = [];
  groupId: string;
  sortOptions = [
    { id: '-ratingsCount', name: 'Δημοτικότητα' },
    { id: 'deliveryTimeMinutes', name: 'Χρόνος Παράδοσης' },
    { id: 'minimumOrderPrice', name: 'Ελάχ. Παραγγελία' },
  ];
  storeGroupId: string;
  public dropdownItem: string;
  addressId: string;
  currentAddress: string;
  searchValue: string;
  sortAttribute: string;
  onFilterActivated: Subscription;

  grouptype: string;

  @Output() searchcriteria = new EventEmitter<String>();
  searchword: string;

  lat: string;
  lng: string;
  postalCode: string;

  constructor(
    private storeReq: RequestsService,
    private user: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.dropdownItem = this.sortOptions[0].name;
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      this.groupId = params.get('storegroup');
      localStorage.setItem('storeGroupId', this.groupId);

      this.grouptype = params.get('grouptype');
    });
    this.postalCode = localStorage.getItem('postalCode');
    this.lat = localStorage.getItem('addressLat');
    this.lng = localStorage.getItem('addressLng');
    this.storeReq
      .getStoresByStoreGroup(this.groupId, this.lat, this.lng, this.postalCode)
      .subscribe((data) => (this.stores = data));
    this.storeGroupId = this.groupId;

    this.onFilterActivated = this.storeReq.onSearch$.subscribe((search) => {
      if (search) {
        this.storeReq
          .getStoresSortBy(this.groupId, this.sortAttribute)
          .subscribe((data) => (this.stores = data));
      }
    });

    let token = localStorage.getItem('token');

    let user = localStorage.getItem('userId');
    this.addressId = localStorage.getItem('addressId');

    this.user.getAddressesByUser(user, token).subscribe((data) => {
      this.addresses = data;
      this.getAddress(this.addressId);
    });

    return this.storeGroupId;
  }

  searchThis(data) {
    console.log(data);
  }
  onSearch(value) {
    console.log(value);
    this.searchValue = value;
    console.log(this.stores);
  }
  goBack() {
    this.router.navigate(['/categories']);
  }

  getAddress(id: string) {
    for (let i = 0; i < this.addresses.length; i++) {
      if (this.addresses[i].id === id) {
        let addressFull = Array.prototype.concat.call(
          this.addresses[i].address +
            ' ' +
            this.addresses[i].number +
            ' ' +
            this.addresses[i].city
        );
        this.currentAddress = addressFull;

        return this.currentAddress;
      }
    }
  }

  getKeys(obj: any): Array<string> {
    return Object.keys(obj);
  }

  sortBy(sortby: string, item) {
    this.storeReq
      .getStoresSortBy(this.groupId, sortby)
      .subscribe((data) => (this.stores = data));
    this.dropdownItem = item;
    this.sortAttribute = sortby;
  }

  changeValue(item) {
    this.dropdownItem = item;
  }

  ngOnDestroy() {
    if (this.onFilterActivated) {
      this.onFilterActivated.unsubscribe;
    }
  }
}
