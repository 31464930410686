import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IStore } from '../models/store';
import { IStoreGroups } from '../models/storeGroups';
import { ISingleStore } from '../models/singleStore';
import { StoreParams } from '../models/storeParams';
import { ICalendar } from '../models/Calendar';
import { IReviewStore } from '../models/ReviewStore';
import { ThrowStmt } from '@angular/compiler';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  private _total = new BehaviorSubject<number>(0);
  totalchange$ = this._total.asObservable();
  private _searchSource = new BehaviorSubject(false);
  onSearch$ = this._searchSource.asObservable();
  private _storesBygroup: IStore[] = [];

  storeParams = new StoreParams();
  selectedFilters: string[] = [];
  sortBy: string;
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getStores(): Observable<IStore[]> {
    return this.http
      .get<IStore[]>(this.apiUrl + '/store?', {
        headers: new HttpHeaders().set(
          'Authorization',
          'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
        ),
      })
      .pipe(map((res: any) => res.data));
  }

  getStoreInfo(id): Observable<IStore> {
    let postalCode = localStorage.getItem('postalCode');
    let lat = localStorage.getItem('addressLat');
    let lng = localStorage.getItem('addressLng');

    return this.http.get<IStore>(
      this.apiUrl +
        '/store/info/' +
        id +
        '&lat=' +
        lat +
        '&lng=' +
        lng +
        '&postCode=' +
        postalCode,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
        ),
      }
    );
  }

  getStoreGroups(): Observable<IStoreGroups[]> {
    let postal = localStorage.getItem('postalCode');
    let query = '?postCode=' + postal;
    let url = 'https://devapi.app.e-cleaning.gr/v2/mob/storegroup' + query;
    return this.http.get<IStoreGroups[]>(url, {
      headers: new HttpHeaders().set(
        'Authorization',
        'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
      ),
    });
  }
  //T. K. + lat lng
  getStoresByStoreGroup(
    groupId: string,
    lat: string,
    lng: string,
    postalCode: string
  ) {
    return this.http
      .get<any>(
        this.apiUrl +
          '/store?storegroup=' +
          groupId +
          '&lat=' +
          lat +
          '&lng=' +
          lng +
          '&postCode=' +
          postalCode,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
          ),
        }
      )
      .pipe(
        map((res) => {
          this._storesBygroup = res.data;
          return res.data;
        })
      );
  }

  getStoreById() {
    let postalCode = localStorage.getItem('postalCode');
    let lat = localStorage.getItem('addressLat');
    let lng = localStorage.getItem('addressLng');
    if (this._storesBygroup.length === 0) {
      let groupId = localStorage.getItem('storeGroupId');
      console.log('I amm here!');
      return this.getStoresByStoreGroup(groupId, lat, lng, postalCode);
    }
    return of(this._storesBygroup);
  }

  getSingleStorebyId(storeId: string) {
    return this.http.get<ISingleStore[]>(this.apiUrl + '/store/' + storeId, {
      headers: new HttpHeaders().set(
        'Authorization',
        'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
      ),
    });
  }

  getStoresSortBy(groupId: string, sortby: string) {
    let postalCode = localStorage.getItem('postalCode');
    let lat = localStorage.getItem('addressLat');
    let lng = localStorage.getItem('addressLng');
    let url =
      this.apiUrl +
      '/store?storegroup=' +
      groupId +
      '&lat=' +
      lat +
      '&lng=' +
      lng +
      '&postCode=' +
      postalCode +
      '&sortBy=' +
      sortby;

    let services = '';

    if (this.selectedFilters.length) {
      services = this.selectedFilters.join(',');
      console.log('Services', services);
      url = url + '&services=' + services;
    }

    return this.http.get<IStore[]>(url, {
      headers: new HttpHeaders().set(
        'Authorization',
        'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
      ),
    });
  }

  updateFilters(filters: string[]) {
    this.selectedFilters = [...filters];
  }
  updateSort(value) {
    this.sortBy = value;
  }

  onSearch() {
    return this._searchSource.next(true);
  }

  getSingleStoreCalendar(storeId: string, postcode: string) {
    return this.http.get<ICalendar[]>(
      this.apiUrl + '/store/calendar/' + storeId,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
        ),
        params: new HttpParams().set('postCode', postcode),
      }
    );
  }

  getStoreReview(storeId: string) {
    return this.http.get<IReviewStore[]>(this.apiUrl + '/review', {
      headers: new HttpHeaders().set(
        'Authorization',
        'fgqlqzRwwpColeHpa8Y-RArRWaaIwKjUJULdXVb_eSFQ-8lpiAknvhG5XN1ifDTP'
      ),
      params: new HttpParams().set('store', storeId),
    });
  }

  getStoreParams() {
    return this.storeParams;
  }

  setStoreParams(params: StoreParams) {
    this.storeParams = params;
  }
}
