<head>
  <style></style>
</head>

<div class="header d-flex justify-content-center align-items-center">
  <div style="cursor: pointer" class="img">
    <a (click)="goHomeNotHidden()">
      <img id="logo" src="../../../assets/Logo.jpg" />
    </a>

    <span class="glyphicon glyphicon-star"></span>
  </div>
  <nav
    class="navbar navbar-light bg-lignt justify-content-between w-100"
    *ngIf="showHide"
  >
    <button
      class="navbar-toggler"
      type="button"
      (click)="showHideContent()"
      data-toggle="collapse"
      data-target="#navbarToggleExternalContent"
      aria-controls="navbarToggleExternalContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <span class="navbar-text blue"> </span>

    <form class="form-inline">
      <div *ngIf="!(this.user.isLogged$ | async)">
        <button
          class="btn btn-outline-light light-background blue"
          (click)="goHomeNotHidden()"
        >
          Σύνδεση/Εγγραφή
        </button>
      </div>
      <div *ngIf="this.user.isLogged$ | async">
        <button
          class="btn btn-outline-light light-background blue"
          (click)="goHomeNotHidden(true)"
        >
          Αποσύνδεση
        </button>
      </div>
    </form>
  </nav>

  <nav
    class="navbar navbar-light bg-lignt justify-content-between headden"
    *ngIf="!showHide"
  >
    <button
      class="navbar-toggler closer"
      type="button"
      (click)="showHideContent()"
      data-toggle="collapse"
      data-target="#navbarToggleExternalContent"
      aria-controls="navbarToggleExternalContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <img id="closer" src="../../../assets/svgWeb/ic_add_24px.svg" />
    </button>
    <span class="navbar-text blue">
      <div class="img">
        <a href="">
          <img id="logo" src="" />
        </a>
      </div>
    </span>

    <form class="form-inline">
      <div *ngIf="!(this.user.isLogged$ | async)">
        <button class="btn btn-outline-light no-bg white" (click)="goHome()">
          Σύνδεση/Εγγραφή
        </button>
      </div>
      <div *ngIf="this.user.isLogged$ | async">
        <button class="btn btn-outline-light no-bg white" (click)="goHome()">
          Αποσύνδεση
        </button>
      </div>
    </form>
  </nav>

  <div class="fullHeight" *ngIf="!showHide">
    <div class="menu">
      <div class="text" style="cursor: pointer" (click)="goHome()">Αρχική</div>

      <div
        *ngIf="this.user.isLogged$ | async"
        class="text"
        style="cursor: pointer"
        (click)="goOrders()"
      >
        Ιστορικό
      </div>
      <div
        *ngIf="this.user.isLogged$ | async"
        class="text"
        style="cursor: pointer"
        (click)="goProfile()"
      >
        Προφίλ
      </div>
      <div class="text" style="cursor: pointer" (click)="goPrivacy()">
        Πολιτική απορρήτου
      </div>
    </div>
  </div>
</div>
